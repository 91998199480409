import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { TOKEN, USERNAME, TEMP_TOKEN, imageConstants } from '../../config/constants';
import Img from 'react-image';
import React, { Component } from 'react';
import { loginUser, getUserDetails } from '../../Services/Authentication/action';
import LocalStorageServices from '../../Services/LocalStorage';
import { getStoreListing } from '../../Services/Store&Product/action';
import './Login.css';
import CustomLoader from '../Loader/Loader';
import config from '../../config/apiConfig';
import Utils from "../../Utils/Utils";


const { translate } = Utils;
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      passwordText: '',
      activityStartFlag: false,
      validationError: false,
      apiError: false,
    };
    this.activeDirectoryLogin();
  }

  handleSubmit(event) {
    const { authenticateUser } = this.props;
    const { username, passwordText } = this.state;
    const apiPayload = {
      username: username,
      password: passwordText,
    };
    event.preventDefault();
    if (!username.length || !passwordText.length) {
      this.setState({ validationError: true })
      return
    }
    this.setState({ activityStartFlag: true, validationError: false })
    authenticateUser(apiPayload, (status, response, error) => {
      if (status) {
        if (
          response &&
          response.headers &&
          response.headers.authorization
        ) {
          LocalStorageServices.setItem(
            TOKEN,
            response.headers.authorization.replace('Bearer ', ''),
          );
          LocalStorageServices.setItem(USERNAME, username);
          LocalStorageServices.setItem(TEMP_TOKEN, 'true');
          const { getListingData } = this.props;
          getListingData((status, response) => {
            this.setState({ activityStartFlag: false });
            if (status) {
              this.props.history.replace('/home')
            } else {
              this.setState({ activityStartFlag: false, apiError: true });
            }
          });
        } else {
          this.setState({ activityStartFlag: false, apiError: true });
        }
      } else {
        this.setState({ activityStartFlag: false, apiError: true });
      }
    });
  }

  componentDidMount() {
    if (LocalStorageServices.getItem(TOKEN)) {
      this.props.history.replace('/home');
    }

  }

  onChangeText = event => {
    this.setState({ username: event.target.value, validationError: false, apiError: false });
  };

  onChangePasswordText = event => {
    this.setState({ passwordText: event.target.value, validationError: false, apiError: false });
  };

  activeDirectoryLogin() {
    let substring = window.location.href.substring(
      window.location.href.indexOf('#') + 1,
    );
    try {
      let params =
        '{"' +
        decodeURI(substring)
          .replace(/"/g, '\\"')

          .replace(/&/g, '","')
          .replace(/[=]/g, '":"') +
        '"}'
      params = JSON.parse(params.replace(/"",/g, ''))
      LocalStorageServices.setItem(TOKEN, params.id_token)
      LocalStorageServices.removeItem(TEMP_TOKEN);
      const { getUserData } = this.props;
      getUserData((status, response) => {
        if (status) {
          try {
            const { user } = response;
            var userName = user.substring(
              user.lastIndexOf("_") + 1,
              user.lastIndexOf("@")
            );
          } catch (error) {
            //
          }
          LocalStorageServices.setItem(
            USERNAME,
            userName || '',
          );
          this.props.history.replace('/home')
        } else {
          this.setState({ apiError: true });
        }
      });
    } catch (error) {
      //
    }
  }

  render() {
    const { activityStartFlag, validationError, apiError } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="left fadeIn first">
          </div>
          <div className="col-sm-3 col-md-6 col-lg-4 right fadeIn first">
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div className='loginBox'>
                <div className='imageContainerLogin'>
                  <Img
                    style={{
                      width: 200,
                      height: 50,
                      marginBottom: 50
                    }}
                    src={imageConstants.axfoodBlackLogo}
                  />
                  <p>{translate.loginIntoText}</p>
                </div>
                <input type="button" style={{ marginTop: 50 }} value={translate.adLoginText} onClick={() => {
                  window.location.href = `${config.cognitoURL}${config.redirectURI}${
                    config.clientId}`;
                }} className="submit fadeIn second" />
              </div>
            </form>
          </div>
        </div>
        <CustomLoader startLoader={activityStartFlag} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // userAuthData: state.authenticationReducer.userAuthData
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: bindActionCreators(loginUser, dispatch),
  getListingData: bindActionCreators(getStoreListing, dispatch),
  getUserData: bindActionCreators(getUserDetails, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
