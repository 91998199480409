import React, { useState, Component } from "react";
import { Button, Dropdown, FormControl, Form } from 'react-bootstrap'
import ReactDom from "react-dom";
import './ScanMaster.css';
import Img from 'react-image';
import {
    getScanProductList,
    getScanMasterList,
    searchScanProductList,
    searchScanMasterList,
    clearScanMasterList,
    getQuantityUnitData,
    getScanMasterFilterList,
} from '../../../Services/Store&Product/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ScanProducts from './ScanProducts/ScanProducts'
import CustomLoader from '../../Loader/Loader';
import { orderBy, filter } from 'lodash';
import Utils from '../../../Utils/Utils';
import { imageConstants, colorConstants } from '../../../config/constants';
import TableSearchBar from '../../Common/SearchBar/SearchBar';
import LocalStorageServices from '../../../Services/LocalStorage';
import Download from '../../Common/DownloadExcel';
import { debounce, uniq, map, sortBy } from 'lodash';
import moment from "moment";


const { translate, ActivityIndicator, getDateString } = Utils;

class ScanMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProductMaster: false,
            scanMasterData: (props.scanMasterData && props.scanMasterData.content) || [],
            totalPages: (props.scanMasterData && props.scanMasterData.totalPages) || null,
            activityStartFlag: false,
            productMasterData: null,
            selectedStore: null,
            type: 'desc',
            sortBy: 'lastTransactionDate',
            searchParam: null,
            pageSize: 15,
            pageNumber: 0,
            resetData: false,
            clearSearch: false,
            sourceFlag: false,
            sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
            selectedSourceFilter: null,
            filterFlag: false,
            scanDateFlag: false,
            nameFlag: false,
            conceptFlag: false,
            scanDateFilterValue: null,
            nameFilterValue: null,
            conceptFilterValue: null,
            dropdownItems: null,
        };
        this.selectedValue = {}
        this.searchData = debounce(this.searchData, 500);
        this.filteredData = ''
    }

    getScanProducts(item) {
        var payload = {
            storeId: item.storeId,
            scanDate: item.scanDate,
            pageSize: 100,
            pageNumber: 0,
            status: 'all',
            filterType: 'status',
            source: item.source
        }
        this.setState({ activityStartFlag: true, clearSearch: true, searchParam: null })
        this.props.getUnitData();
        this.props.getScanProductList(payload, (status, response) => {
            if (status) {
                this.setState({
                    showProductMaster: true,
                    activityStartFlag: false,
                    clearSearch: false,
                    selectedStore: item,
                    productMasterData: response,
                    savedProductMaster: response,
                })
            } else {
                this.setState({ activityStartFlag: false })
            }
        })
    }

    refreshPrevData() {
        this.setState({
            showProductMaster: true,
            activityStartFlag: false,
            productMasterData: this.props.scanProducts,
            savedProductMaster: this.props.scanProducts,
        })
    }

    sort(data, key, type) {
        return orderBy(data, key, type)
    }

    handleLogout() {
        LocalStorageServices.removeAllItem();
        this.props.history.replace('/');
    }
    searchData(value) {
        const { showProductMaster, selectedStore } = this.state;
        const { getSearchProductScan, getSearchScanMaster } = this.props;
        try {
            if (value && value.length > 1) {
                if (value !== this.state.searchParam) {
                    this.setState({ searchParam: value })
                    if (showProductMaster) {
                        var payload = {
                            storeId: selectedStore.storeId,
                            scanDate: selectedStore.scanDate,
                            searchParam: value,
                        }
                        getSearchProductScan(payload);
                    } else {
                        getSearchScanMaster(value, (status, response) => {
                            if (status) {
                                this.setState({ scanMasterData: response, totalPages: null })
                            }
                        });
                    }
                }
            } else {
                this.setState((prevState) => ({
                    scanMasterData: [],
                    productMasterData: prevState.savedProductMaster,
                    pageNumber: 0,
                    searchParam: null
                }), () => {
                    this.pagination();
                    if (showProductMaster) {
                        if (!value.length) {
                            this.setState({ resetData: true }, () => {
                                this.setState({ resetData: false })
                                this.getScanProducts(selectedStore)
                            })
                        }
                    }
                })
            }
        } catch (error) {
            //
        }

    }

    componentWillReceiveProps(nextProps) {
        const { scanMasterData, pageNumber } = this.state;
        if (this.props.scanMasterData.content !== nextProps.scanMasterData.content
            && scanMasterData !== nextProps.scanMasterData.content
            && nextProps.scanMasterData.number === pageNumber
            && JSON.stringify(nextProps.scanMasterData.content) !== JSON.stringify(scanMasterData)) {
            var updatedData = [...scanMasterData, ...nextProps.scanMasterData.content]
            this.setState({
                scanMasterData: updatedData,
                totalPages: (nextProps.scanMasterData && nextProps.scanMasterData.totalPages) || null,
            })
        }
    }

    componentDidMount() {
        {
            this.props.conceptData && (setTimeout(() => {
                const uniqueConceptNames = sortBy(
                    uniq(this.props.conceptData.map((item) => item.name))
                );

                // Update state when data is available
                this.setState({ dropdownItems: uniqueConceptNames });
            }, 500))
        }
        const { scanMasterData, activityStartFlag } = this.state;
        if (!(scanMasterData && scanMasterData.length) && !activityStartFlag) {
            this.pagination(0);
            localStorage.removeItem("localSourceFilter");
            localStorage.removeItem("localScanDateFilter");
            localStorage.removeItem("localNameFilter");
            localStorage.removeItem("localConceptFilter");
        }

        this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
        this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
        this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
        this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
        ReactDom.findDOMNode(this).scrollIntoView();


    }

    async componentWillUnmount() {
        const { clearScanMasterData } = this.props
        clearScanMasterData([]);

    }

    pagination(paramPageNumber) {
        const { getScanMasterData } = this.props
        const { pageSize, pageNumber, filterFlag, selectedSourceFilter, scanDateFilterValue, nameFilterValue, conceptFilterValue } = this.state
        const customPageNumber = paramPageNumber > 0 ? paramPageNumber : pageNumber
        if (filterFlag) {
            let payload = {
                pageSize: pageSize,
                pageNumber: pageNumber,
                selectedSourceFilter,
                scanDateFilterValue,
                nameFilterValue,
                conceptFilterValue,
            }
            this.props.getScanMasterFilterData(payload, (status, response) => {
                // console.log('RESPONSE-----------', response);
                this.setState({ pageNumber: pageNumber + 1 })
            })
            return
        }
        getScanMasterData(pageSize, customPageNumber, (status, response) => {
            this.setState({ pageNumber: customPageNumber + 1 })
        })
    }



    render() {
        const { showProductMaster,
            pageSize,
            activityStartFlag,
            selectedStore,
            type,
            sortBy,
            searchParam,
            pageNumber,
            resetData,
            totalPages,
            clearSearch,
            sourceFlag,
            sourceFilterData,
            scanDateFlag,
            nameFlag,
            conceptFlag,
            scanMasterData,
            scanDateFilterValue,
            nameFilterValue,
            conceptFilterValue,
            selectedSourceFilter,
            dropdownItems,
        } = this.state;
        // console.log('======>', this.props.conceptData);
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <span
                className='custom-header cursor'
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <Img
                    style={{
                        width: 12,
                        marginLeft: 5
                    }}
                    decode={false}
                    src={imageConstants.filterIcon}
                />
            </span>
        ));
        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'Montserrat': labeledBy }, ref) => {
                const [value, setValue] = React.useState('');
                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto font-13"
                            placeholder={translate.typeToFilterText}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                        <ul className="list-unstyled">
                            <div className='scrollable-menu'>
                                {React.Children.toArray(children[0])
                                    .filter(
                                        (child) =>
                                            !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                                    )
                                }
                            </div>
                            {React.Children.toArray(children[1])}
                        </ul>
                    </div>

                );
            },
        );
        const CustomSearchMenu = React.forwardRef(
            ({ children, style, className, 'Montserrat': labeledBy, setDateValue }, ref) => {
                const [value, setValue] = React.useState('');
                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <FormControl
                            autoFocus
                            type="date"
                            className="mx-3 my-2 w-auto font-13"
                            class="form-control"
                            max={new Date().toISOString().slice(0, 10)}
                            placeholder={translate.typeToFilterText}
                            onChange={(e) => {
                                setValue(e.target.value)
                            }}
                            onKeyPress={(event) => {
                                if (event?.nativeEvent?.charCode === 13 && value === moment(value).format('YYYY-MM-DD')) {
                                    setDateValue(getDateString(value))
                                }
                            }
                            }
                            // onSubmit={(e) => {
                            //     console.log(e)
                            //     setDateValue(getDateString(e.target.value))
                            // }}
                            value={value}
                        />
                        <ul className="list-unstyled">
                            <div className='scrollable-menu'>
                                {React.Children.toArray(children[0])
                                    .filter(
                                        (child) =>
                                            !value || (child.props.children && child.props.children.includes(value)),
                                    )
                                }
                            </div>
                            {React.Children.toArray(children[1])}
                        </ul>
                    </div>

                );
            },
        );
        const CustomNameSearchMenu = React.forwardRef(
            ({ children, style, className, 'Montserrat': labeledBy, setNameValue }, ref) => {
                const [value, setValue] = React.useState('');
                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto font-13"
                            class="form-control"
                            placeholder={translate.typeToFilterText}
                            onChange={(e) => {
                                setValue(e.target.value)
                            }}
                            onKeyPress={(event) => {
                                if (event?.nativeEvent?.charCode === 13) {
                                    setNameValue(value)
                                }
                            }
                            }
                            value={value}
                        />
                        <ul className="list-unstyled">
                            <div className='scrollable-menu'>
                                {React.Children.toArray(children[0])
                                    .filter(
                                        (child) =>
                                            !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                                    )
                                }
                            </div>
                            {React.Children.toArray(children[1])}
                        </ul>
                    </div>

                );
            },
        );
        const ConceptSearchMenu = React.forwardRef(
            ({ children, style, className, 'Montserrat': labeledBy, setConceptValue }, ref) => {
                const [value, setValue] = React.useState('');
                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto font-13"
                            class="form-control"
                            placeholder={translate.typeToFilterText}
                            onChange={(e) => {
                                setValue(e.target.value)
                            }}
                            onKeyPress={(event) => {
                                if (event?.nativeEvent?.charCode === 13) {
                                    setConceptValue(value)
                                }
                            }
                            }
                            value={value}
                        />
                        <ul className="list-unstyled">
                            <div className='scrollable-menu'>
                                {/* {React.Children.toArray(children[0])
                                    .filter(
                                        (child) =>
                                            !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                                    )
                                } */}
                            </div>
                            {React.Children.toArray(children[1])}
                        </ul>
                    </div>

                );
            },
        );
        return (
            <>
                <div className='scan-bottom-div custom-display'>

                    <TableSearchBar onLogoutClick={this.handleLogout.bind(this)} onSearch={this.searchData.bind(this)} clearSearch={clearSearch}
                        placeholderText={showProductMaster ? translate.searchByProductMasterText : translate.searchByText}
                    />

                    <div className='custom-padding'>
                        {
                            (selectedStore) ?
                                <ScanProducts resetData={resetData} onSearch={this.searchData.bind(this)} searchParam={searchParam} selectedStore={selectedStore} refreshPrevData={() => { this.refreshPrevData() }} />
                                :
                                <div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <p className='custom-store'>{translate.scanMasterText}</p>
                                        <div>
                                            {this.state.filterFlag ? <Button className="btn add-store" onClick={() => {
                                                localStorage.removeItem("localSourceFilter");
                                                localStorage.removeItem("localScanDateFilter");
                                                localStorage.removeItem("localNameFilter");
                                                localStorage.removeItem("localConceptFilter");
                                                this.setState({ scanDateFlag: false, filterFlag: false, sourceFlag: false, nameFlag: false, conceptFlag: false }, () => {
                                                    this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                        window.location.reload();
                                                    })
                                                })
                                            }}>
                                                <Img
                                                    style={{
                                                        width: 14,
                                                        marginRight: 7
                                                    }}
                                                    decode={false}
                                                    src={imageConstants.filterIcon}
                                                />
                                                <span className='custom-class'>{translate.removeFilterText}</span>
                                            </Button> : null}
                                            <Download selection={true} data={{ type: 'scanMasterData', value: scanMasterData }} />
                                            <Button className="btn add-store" onClick={() => {
                                                this.setState({ scanMasterData: [], pageNumber: 0 }, () => {
                                                    this.pagination();
                                                })
                                            }}>
                                                <Img
                                                    style={{
                                                        width: 20,
                                                        marginRight: 10
                                                    }}
                                                    decode={false}
                                                    src={imageConstants.refreshIcon}
                                                />
                                                <span className='custom-class'>{translate.refreshText}</span>
                                            </Button>
                                        </div>

                                    </div>

                                    <table className="table table-borderless table-custom-header">
                                        <thead className='custom-thead'>
                                            <tr className='ml-1 mr-2 mt-2'>
                                                <th scope="col" colSpan="2">{translate.storeIdText}</th>
                                                {searchParam ? <th scope="col">{translate.nameText}</th> : <th scope="col" ><Dropdown show={nameFlag} onToggle={() => {
                                                    this.setState({ nameFlag: !nameFlag });
                                                }}>
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                        {translate.nameText}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={CustomNameSearchMenu} setNameValue={(val) => {
                                                        this.setState({ nameFilterValue: val, nameFlag: false }, () => {

                                                            this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
                                                            this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
                                                            this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                            this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                            this.setState({ scanMasterData: [], pageNumber: 0, filterFlag: true }, () => {
                                                                let payload = {
                                                                    pageSize: pageSize,
                                                                    pageNumber: 0,
                                                                    scanDateFilterValue: this.localScanDateFilterType || null,
                                                                    selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                    conceptFilterValue: this.localConceptFilterType || null,
                                                                    nameFilterValue: val,
                                                                }

                                                                localStorage.setItem("localNameFilter", JSON.stringify(val));

                                                                this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                    this.setState({ pageNumber: 1 })
                                                                })
                                                            })
                                                        })
                                                    }}>

                                                        {nameFilterValue && <Dropdown.Item >
                                                            {nameFilterValue}
                                                        </Dropdown.Item>}
                                                        {
                                                            nameFilterValue && (
                                                                <>
                                                                    <div className="removeButton" onClick={() => {
                                                                        localStorage.removeItem("localNameFilter");
                                                                        this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"));
                                                                        this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"));
                                                                        this.localConceptFilterType = JSON.parse(localStorage.getItem("localConceptFilter"));
                                                                        this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                                        const payload = {
                                                                            pageSize: pageSize,
                                                                            pageNumber: 0,
                                                                            selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                            scanDateFilterValue: this.localScanDateFilterType || null,
                                                                            conceptFilterValue: this.localConceptFilterType || null,
                                                                        };

                                                                        if (this.localSourceFilterType || this.localConceptFilterType || this.localScanDateFilterType) {
                                                                            this.setState({
                                                                                nameFilterValue: null,
                                                                                scanDateFilterValue: this.localScanDateFilterType || null,
                                                                                nameFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: this.localSourceFilterType || ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: true,
                                                                                selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                                conceptFilterValue: this.localConceptFilterType || null,
                                                                            }, () => {
                                                                                this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                });
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                nameFilterValue: null,
                                                                                scanDateFilterValue: null,
                                                                                nameFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: false,
                                                                                selectedSourceFilter: null,
                                                                                conceptFilterValue: null,
                                                                            }, () => {
                                                                                this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                    if (status) {
                                                                                        window.location.reload();
                                                                                    }
                                                                                });
                                                                            });
                                                                        }
                                                                    }}>
                                                                        <span className="btn_apply" id="apply">{translate.removeText}</span>
                                                                    </div>

                                                                    <div className="bottomView" />
                                                                </>
                                                            )
                                                        }

                                                    </Dropdown.Menu>

                                                </Dropdown></th>}
                                                <th scope="col">{translate.addressText}</th>
                                                {/* {searchParam ? <th scope="col">{translate.conceptText}</th> : <th scope="col" ><Dropdown show={conceptFlag} onToggle={() => {
                                                    this.setState({ conceptFlag: !conceptFlag });
                                                }}>
                                                    
                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                        {translate.conceptText}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={ConceptSearchMenu} setConceptValue={(val) => {
                                                        this.setState({ conceptFilterValue: val, conceptFlag: false }, () => {

                                                            this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
                                                            this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
                                                            this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                            this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                            this.setState({ scanMasterData: [], pageNumber: 0, filterFlag: true }, () => {
                                                                let payload = {
                                                                    pageSize: pageSize,
                                                                    pageNumber: 0,
                                                                    scanDateFilterValue: this.localScanDateFilterType || null,
                                                                    selectedSourceFilter: this.localSourceFilterType || null,
                                                                    nameFilterValue: this.localNameFilterType || null,
                                                                    conceptFilterValue: val,
                                                                }

                                                                localStorage.setItem("localConceptFilter", JSON.stringify(val));

                                                                this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                    this.setState({ pageNumber: 1 })
                                                                })
                                                            })
                                                        })
                                                    }}>

                                                        {conceptFilterValue && <Dropdown.Item >
                                                            {conceptFilterValue}
                                                        </Dropdown.Item>}
                                                        {
                                                            conceptFilterValue && (
                                                                <>
                                                                    <div className="removeButton" onClick={() => {
                                                                        localStorage.removeItem("localConceptFilter");
                                                                        this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"));
                                                                        this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"));
                                                                        this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"));
                                                                        this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                                        const payload = {
                                                                            pageSize: pageSize,
                                                                            pageNumber: 0,
                                                                            selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                            scanDateFilterValue: this.localScanDateFilterType || null,
                                                                            nameFilterValue: this.localNameFilterType || null,
                                                                        };

                                                                        if (this.localSourceFilterType || this.localNameFilterType || this.localScanDateFilterType) {
                                                                            this.setState({
                                                                                conceptFilterValue: [],
                                                                                scanDateFilterValue: this.localScanDateFilterType || null,
                                                                                conceptFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: this.localSourceFilterType || ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: true,
                                                                                selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                                nameFilterValue: this.localNameFilterType || null,
                                                                            }, () => {
                                                                                this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                });
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                conceptFilterValue: [],
                                                                                scanDateFilterValue: null,
                                                                                conceptFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: false,
                                                                                selectedSourceFilter: null,
                                                                                nameFilterValue: null,
                                                                            }, () => {
                                                                                this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                    if (status) {
                                                                                        window.location.reload();
                                                                                    }
                                                                                });
                                                                            });
                                                                        }
                                                                    }}>
                                                                        <span className="btn_apply" id="apply">{translate.removeText}</span>
                                                                    </div>
                                                                    <div className="bottomView" />

                                                                </>
                                                            )
                                                        }

                                                    </Dropdown.Menu>

                                                </Dropdown></th>} */}
                                                {searchParam ? <th scope="col">{translate.conceptText}</th> : <th scope="col" ><Dropdown show={conceptFlag} onToggle={() => {
                                                    this.setState({ conceptFlag: !conceptFlag });
                                                }}>

                                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                        {translate.conceptText}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu as={ConceptSearchMenuMulti}
                                                        onSave={(val) => {
                                                            if (val.length) {
                                                                this.setState({ conceptFilterValue: val, conceptFlag: false }, () => {

                                                                    this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
                                                                    this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
                                                                    this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                                    this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                                    this.setState({ scanMasterData: [], pageNumber: 0, filterFlag: true }, () => {
                                                                        let payload = {
                                                                            pageSize: pageSize,
                                                                            pageNumber: 0,
                                                                            scanDateFilterValue: this.localScanDateFilterType || null,
                                                                            selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                            nameFilterValue: this.localNameFilterType || null,
                                                                            conceptFilterValue: val,
                                                                        }

                                                                        localStorage.setItem("localConceptFilter", JSON.stringify(val));

                                                                        this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                            this.setState({ pageNumber: 1 })
                                                                        })
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                        onClear={() => {
                                                            localStorage.removeItem("localConceptFilter");
                                                            this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"));
                                                            this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"));
                                                            this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"));
                                                            this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                            const payload = {
                                                                pageSize: pageSize,
                                                                pageNumber: 0,
                                                                selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                scanDateFilterValue: this.localScanDateFilterType || null,
                                                                nameFilterValue: this.localNameFilterType || null,
                                                            };

                                                            if (this.localSourceFilterType || this.localNameFilterType || this.localScanDateFilterType) {
                                                                this.setState({
                                                                    conceptFilterValue: null,
                                                                    scanDateFilterValue: this.localScanDateFilterType || null,
                                                                    conceptFlag: false,
                                                                    pageNumber: 0,
                                                                    scanMasterData: [],
                                                                    sourceFilterData: this.localSourceFilterType || ['WEBCRAWLER', 'PRISKOLL'],
                                                                    filterFlag: true,
                                                                    selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                    nameFilterValue: this.localNameFilterType || null,
                                                                }, () => {
                                                                    this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                        this.setState({ pageNumber: 1 });
                                                                    });
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    conceptFilterValue: null,
                                                                    scanDateFilterValue: null,
                                                                    conceptFlag: false,
                                                                    pageNumber: 0,
                                                                    scanMasterData: [],
                                                                    sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                    filterFlag: false,
                                                                    selectedSourceFilter: null,
                                                                    nameFilterValue: null,
                                                                }, () => {
                                                                    this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                                        this.setState({ pageNumber: 1 });
                                                                        if (status) {
                                                                            window.location.reload();
                                                                        }
                                                                    });
                                                                });
                                                            }
                                                        }}
                                                        items={dropdownItems}
                                                    >
                                                        {console.log('==============================>', dropdownItems)};

                                                    </Dropdown.Menu>

                                                </Dropdown></th>}
                                                {searchParam ? <th scope="col">{translate.source}</th> : <th scope="col">
                                                    <Dropdown show={sourceFlag} onToggle={() => {
                                                        this.setState({ sourceFlag: !sourceFlag });
                                                    }}>
                                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                            {translate.source}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu as={CustomMenu}>
                                                            {
                                                                sourceFilterData?.map((item, index) => {
                                                                    return (
                                                                        <Dropdown.Item key={index}
                                                                            onClick={() => {
                                                                                var data = filter(sourceFilterData, value => value === item);
                                                                                console.log('data', data[0]);
                                                                                this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
                                                                                this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
                                                                                this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                                                this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                                                this.setState({ scanMasterData: [], pageNumber: 0, sourceFilterData: data, filterFlag: true, selectedSourceFilter: data[0] }, () => {
                                                                                    let payload = {
                                                                                        pageSize: pageSize,
                                                                                        pageNumber: 0,
                                                                                        selectedSourceFilter: data[0],
                                                                                        scanDateFilterValue: this.localScanDateFilterType || null,
                                                                                        nameFilterValue: this.localNameFilterType || null,
                                                                                        conceptFilterValue: this.localConceptFilterType || null,
                                                                                    }
                                                                                    localStorage.setItem("localSourceFilter", JSON.stringify(data));

                                                                                    this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                                        this.setState({ pageNumber: 1 })
                                                                                    })
                                                                                })
                                                                            }} eventKey={index}>{item}</Dropdown.Item>
                                                                    )
                                                                })
                                                            }

                                                            <div className="removeButton" onClick={() => {
                                                                localStorage.removeItem("localSourceFilter");
                                                                this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"));
                                                                this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"));
                                                                this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"));
                                                                this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'));

                                                                const payload = {
                                                                    pageSize: pageSize,
                                                                    pageNumber: 0,
                                                                    scanDateFilterValue: this.localScanDateFilterType || null,
                                                                    nameFilterValue: this.localNameFilterType || null,
                                                                    conceptFilterValue: this.localConceptFilterType || null,
                                                                };

                                                                if (this.localScanDateFilterType || this.localNameFilterType || this.localConceptFilterType) {
                                                                    this.setState({
                                                                        sourceFlag: false,
                                                                        pageNumber: 0,
                                                                        scanMasterData: [],
                                                                        sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                        filterFlag: true,
                                                                        scanDateFilterValue: this.localScanDateFilterType || null,
                                                                        selectedSourceFilter: null,
                                                                        nameFilterValue: this.localNameFilterType || null,
                                                                        conceptFilterValue: this.localConceptFilterType || null,
                                                                    }, () => {
                                                                        this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                            this.setState({ pageNumber: 1 });
                                                                        });
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        sourceFlag: false,
                                                                        pageNumber: 0,
                                                                        scanMasterData: [],
                                                                        sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                        filterFlag: false,
                                                                        selectedSourceFilter: null,
                                                                        nameFilterValue: null,
                                                                        conceptFilterValue: null,
                                                                    }, () => {
                                                                        this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                                            this.setState({ pageNumber: 1 });
                                                                            window.location.reload();
                                                                        });
                                                                    });
                                                                }
                                                            }}>
                                                                <span className="btn_apply" id="apply">{translate.removeText}</span>
                                                            </div>
                                                            <div className="bottomView" />
                                                        </Dropdown.Menu>

                                                    </Dropdown>

                                                </th>}
                                                {searchParam ? <th scope="col" colSpan="2" className='cursor'>{translate.scanDateText}</th> : <th scope="col" colSpan="2" className='cursor' onClick={() => {
                                                    // this.setState((prevState) => ({
                                                    //     type: prevState.type === 'desc' ? 'asc' : 'desc',
                                                    //     sortBy: 'lastTransactionDate'
                                                    // }))
                                                }}>
                                                    {/* <Img
                                                        style={{
                                                            width: 6,
                                                            marginLeft: 5
                                                        }}
                                                        decode={false}
                                                        src={imageConstants.sortArrowIcon}
                                                    /> */}
                                                    <Dropdown show={scanDateFlag} onToggle={() => {
                                                        this.setState({ scanDateFlag: !scanDateFlag });
                                                    }}>
                                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                            {translate.scanDateText}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu as={CustomSearchMenu} setDateValue={(val) => {
                                                            this.setState({ scanDateFilterValue: val, scanDateFlag: false }, () => {

                                                                this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"))
                                                                this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"))
                                                                this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"))
                                                                this.localConceptFilterType = JSON.parse(localStorage.getItem('localConceptFilter'))
                                                                this.setState({ scanMasterData: [], pageNumber: 0, filterFlag: true }, () => {
                                                                    let payload = {
                                                                        pageSize: pageSize,
                                                                        pageNumber: 0,
                                                                        selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                        nameFilterValue: this.localNameFilterType || null,
                                                                        conceptFilterValue: this.localConceptFilterType || null,
                                                                        scanDateFilterValue: val,
                                                                    }

                                                                    this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                        this.setState({ pageNumber: 1 })
                                                                        if (status) {
                                                                            localStorage.setItem("localScanDateFilter", JSON.stringify(val));
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        }}>

                                                            {scanDateFilterValue && <Dropdown.Item >
                                                                {scanDateFilterValue}
                                                            </Dropdown.Item>}
                                                            {scanDateFilterValue && (
                                                                <>
                                                                    <div className="removeButton" onClick={() => {
                                                                        localStorage.removeItem("localScanDateFilter");

                                                                        this.localSourceFilterType = JSON.parse(localStorage.getItem("localSourceFilter"));
                                                                        this.localScanDateFilterType = JSON.parse(localStorage.getItem("localScanDateFilter"));
                                                                        this.localNameFilterType = JSON.parse(localStorage.getItem("localNameFilter"));
                                                                        this.localConceptFilterType = JSON.parse(localStorage.getItem("localConceptFilter"));

                                                                        const payload = {
                                                                            pageSize: pageSize,
                                                                            pageNumber: 0,
                                                                            selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                            nameFilterValue: this.localNameFilterType || null,
                                                                            conceptFilterValue: this.localConceptFilterType || null,
                                                                        };

                                                                        if (this.localSourceFilterType || this.localConceptFilterType || this.localNameFilterType) {
                                                                            this.setState({
                                                                                scanDateFilterValue: null,
                                                                                scanDateFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: this.localSourceFilterType || ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: true,
                                                                                selectedSourceFilter: this.localSourceFilterType ? this.localSourceFilterType[0] : null,
                                                                                nameFilterValue: this.localNameFilterType || null,
                                                                                conceptFilterValue: this.localConceptFilterType || null,
                                                                            }, () => {
                                                                                this.props.getScanMasterFilterData(payload, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                });
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                scanDateFilterValue: null,
                                                                                scanDateFlag: false,
                                                                                pageNumber: 0,
                                                                                scanMasterData: [],
                                                                                sourceFilterData: ['WEBCRAWLER', 'PRISKOLL'],
                                                                                filterFlag: false,
                                                                                selectedSourceFilter: null,
                                                                                conceptFilterValue: null,
                                                                                nameFilterValue: null,
                                                                            }, () => {
                                                                                this.props.getScanMasterData(pageSize, 0, (status, response) => {
                                                                                    this.setState({ pageNumber: 1 });
                                                                                    if (status) {
                                                                                        window.location.reload();
                                                                                    }
                                                                                });
                                                                            });
                                                                        }
                                                                    }}>
                                                                        <span className="btn_apply" id="apply">{translate.removeText}</span>
                                                                    </div>
                                                                    <div className="bottomView" />
                                                                </>)}
                                                        </Dropdown.Menu>

                                                    </Dropdown>
                                                </th>}
                                                <th scope="col" colSpan="3" style={{ textAlign: 'center' }}>
                                                    {translate.scanPendingText}
                                                </th>
                                            </tr>
                                            <tr className='ml-1 mr-2 mt-2 second-head'>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color'></th>
                                                <th className='custom-color cursor' colSpan="1" onClick={() => {
                                                    this.setState((prevState) => ({
                                                        type: prevState.type === 'desc' ? 'asc' : 'desc',
                                                        sortBy: 'approved'
                                                    }))
                                                }}>
                                                    {translate.approvedText}
                                                    <Img
                                                        style={{
                                                            width: 6,
                                                            marginLeft: 5
                                                        }}
                                                        decode={false}
                                                        src={imageConstants.sortArrowIcon}
                                                    />
                                                </th>
                                                <th className='custom-color cursor' colSpan="1" onClick={() => {
                                                    this.setState((prevState) => ({
                                                        type: prevState.type === 'desc' ? 'asc' : 'desc',
                                                        sortBy: 'pending'
                                                    }))
                                                }}>
                                                    {translate.pendingText}
                                                    <Img
                                                        style={{
                                                            width: 6,
                                                            marginLeft: 5
                                                        }}
                                                        decode={false}
                                                        src={imageConstants.sortArrowIcon}
                                                    />
                                                </th>
                                                <th className='custom-color cursor' colSpan="1" onClick={() => {
                                                    this.setState((prevState) => ({
                                                        type: prevState.type === 'desc' ? 'asc' : 'desc',
                                                        sortBy: 'rejected'
                                                    }))
                                                }}>
                                                    {translate.rejectedText}
                                                    <Img
                                                        style={{
                                                            width: 6,
                                                            marginLeft: 5
                                                        }}
                                                        decode={false}
                                                        src={imageConstants.sortArrowIcon}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                scanMasterData ?
                                                    this.sort(scanMasterData, [sortBy], type).map((item, index) => {
                                                        return (


                                                            <tr onClick={() => {
                                                                this.getScanProducts(item);
                                                            }} key={index} className='custom-row shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-2 cursor'>
                                                                <td>{item.storeNumber}</td>
                                                                <td></td>
                                                                <td>{item.name}</td>
                                                                <td>{item.address}</td>
                                                                <td>{item.concept}</td>
                                                                <td>{item.source.toLowerCase().charAt(0).toUpperCase() + item.source.toLowerCase().slice(1)}</td>
                                                                <td>{item.scanDate}</td>
                                                                <td></td>
                                                                <td>{item.approved}</td>
                                                                <td>{item.pending}</td>
                                                                <td>{item.rejected}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                            {/* {
                                                (scanMasterData.length && totalPages && pageNumber < totalPages && !searchParam) ?
                                                    <span
                                                        onClick={() => {
                                                            this.pagination()
                                                        }}
                                                        style={{
                                                            color: colorConstants.darkBlue,
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                        }}>{translate.loadMoreText}</span>
                                                    : null
                                            } */}
                                            {
                                                (totalPages && pageNumber < totalPages && !searchParam) ?
                                                    <span
                                                        onClick={() => {
                                                            this.pagination()
                                                        }}
                                                        style={{
                                                            color: colorConstants.darkBlue,
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                        }}>{translate.loadMoreText}</span>
                                                    : null
                                            }

                                        </tbody>
                                    </table>

                                </div>
                        }
                        <CustomLoader startLoader={activityStartFlag} />
                    </div>
                    {
                        (!scanMasterData.length && !searchParam && !showProductMaster && (selectedSourceFilter === null && scanDateFilterValue === null && nameFilterValue === null && conceptFilterValue === null)) ?
                            <>
                                <ActivityIndicator
                                    style={{
                                        marginLeft: 20,
                                        alignSelf: "center",
                                        marginTop: 150,
                                        height: 100,
                                        width: 100
                                    }} />
                            </>
                            :
                            null
                    }
                </div >
            </>
        )
    }
}





const ConceptSearchMenuMulti = React.forwardRef(
    (
        {
            children,
            style,
            className,
            "aria-labelledby": labeledBy,
            onSave,
            onClear,
            items
        },
        ref
    ) => {
        const [conceptFilterValue, setConceptFilterValue] = useState([]);
        const [value, setValue] = useState("");

        const handleItemToggle = (itemValue) => {
            if (conceptFilterValue.includes(itemValue)) {
                setConceptFilterValue(
                    conceptFilterValue.filter((item) => item !== itemValue)
                );
                console.log("Selected items:======", conceptFilterValue);
            } else {
                setConceptFilterValue([...conceptFilterValue, itemValue]);
                console.log("Selected items:-------", conceptFilterValue);
            }
        };

        const handleSave = () => {
            onSave(conceptFilterValue);
            console.log("Selected items:", conceptFilterValue);
        };

        const handleClear = () => {
            onClear();
            setConceptFilterValue([]);
            console.log("Selected items befiore cleared:", conceptFilterValue);
        };

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul
                    className="list-unstyled"
                    style={{
                        maxHeight: "205px", // Set the maximum height for the dropdown
                        overflowY: "auto", // Enable vertical scrolling if needed
                        width: "232px",
                        paddingLeft: "10px",
                        paddingBottom: "40px",
                    }}
                >
                    {items
                        .filter((item) => !value || item.toLowerCase().startsWith(value))
                        .map((item, index) => {
                            const eventKey = index.toString();
                            const selected = conceptFilterValue.includes(item);

                            return (
                                <li
                                    key={eventKey}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={item}
                                        checked={selected}
                                        onChange={() => handleItemToggle(item)}
                                    />
                                </li>
                            );
                        })}
                </ul>
                <div onClick={handleSave}>
                    <span style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: 30,
                        width: '100%',
                        backgroundColor: '#4f8180',
                        color: '#ffff',
                        textAlign: 'center',
                        padding: '5px',
                    }}>{translate.saveText}</span>
                </div>
                <div onClick={handleClear}>
                    <span style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: '#4f8180',
                        color: '#ffff',
                        textAlign: 'center',
                        padding: '5px',
                    }}>{translate.removeText}</span>
                </div>
            </div>
        );
    }
);




const mapStateToProps = state => ({
    scanMasterData: state.storeReducer.scanMaster,
    scanProducts: state.storeReducer.scanProducts
});

const mapDispatchToProps = dispatch => ({
    getScanProductList: bindActionCreators(getScanProductList, dispatch),
    getScanMasterData: bindActionCreators(getScanMasterList, dispatch),
    getScanMasterFilterData: bindActionCreators(getScanMasterFilterList, dispatch),
    getSearchProductScan: bindActionCreators(searchScanProductList, dispatch),
    getSearchScanMaster: bindActionCreators(searchScanMasterList, dispatch),
    clearScanMasterData: bindActionCreators(clearScanMasterList, dispatch),
    getUnitData: bindActionCreators(getQuantityUnitData, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanMaster));
