import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap'
import './SearchBar.css';
import Img from 'react-image';
import Utils from '../../../Utils/Utils';
import LocalStorageServices from '../../../Services/LocalStorage';
import { LANGUAGE, imageConstants } from '../../../config/constants';
import {
  colorConstants,
} from '../../../config/constants';


const { translate } = Utils;

const TableSearchBar = (props) => {

  const [searchParam, setSearchParam] = useState(null);

  const _onKeyPress = (event) => {
    if (event?.nativeEvent?.charCode === 13 ) {
      if (searchParam && searchParam.length > 0) {
        props.onSearch(searchParam,true);
      }
    }
  }

  const handleChange = (event) => {
    if (event && event.target.value && event.target.value.length > 0) {
      setSearchParam(event && event.target.value)
    } else {
      props.onSearch('');
      setSearchParam('')
    }
  };

  if (props.clearSearch && searchParam && searchParam.length) {
    setSearchParam('')
  }

  return (
    <div className="shadow-lg p-4 mb-5 
    bg-white rounded d-flex 
    align-items-center flex-row 
    justify-content-between">
      <div className="d-flex flex-row align-items-center w-100">
        {
          (props.disabled) ?
            null
            :
            <>
              <Img
                style={{
                  width: 18,
                  height: 18
                }}
                decode={false}
                src={imageConstants.searchIcon}
              />
              <input
                className="textSearch"
                onChange={handleChange}
                onKeyPress={_onKeyPress}
                type="text"
                value={searchParam}
                style={{ width: '100%', paddingLeft: 0 }}
                placeholder={props.placeholderText || translate.searchByText} />
              {
                searchParam && searchParam.length ?
                  <Img
                    style={{
                      width: 25,
                      height: 25
                    }}
                    decode={false}
                    onClick={() => {
                      props.onSearch('');
                      setSearchParam('')
                    }}
                    src={imageConstants.searchCancelIcon}
                  /> :
                  null
              }

            </>
        }

      </div>
      <Dropdown >
        <Dropdown.Toggle style={{ backgroundColor: colorConstants.whiteColor, color: colorConstants.black, borderWidth: 0, textAlign: "center" }} id="dropdown-custom-components">
          <Img
            style={{
              width: 22,
              marginRight: 5,
              marginBottom: 5,
            }}
            decode={false}
            src={imageConstants.globeIcon}
          />
          {LocalStorageServices.getItem(LANGUAGE) === 'sw' ? 'Svenska' : 'ENGLISH'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item key={0}
            onClick={() => {
              LocalStorageServices.setItem(LANGUAGE, 'en');
              window.location.reload();
            }}
            eventKey={0}>English</Dropdown.Item>
          <Dropdown.Item key={1}
            onClick={() => {
              LocalStorageServices.setItem(LANGUAGE, 'sw');
              window.location.reload();
            }}
            eventKey={0}>Svenska</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Button className="btn login btn-custom custom-btn" style={{ backgroundColor: colorConstants.whiteColor }} id="#login" onClick={props.onLogoutClick}>
        <Img
          style={{
            width: 18,
            height: 18,
            marginRight: 10
          }}
          decode={false}
          src={imageConstants.logoutIcon}
        />
        <span className='custom-logout'>{translate.logoutText}</span>
      </Button>
    </div>
  );
};

export default TableSearchBar;