import {USER_AUTH_SUCCESS} from './constants';
import config from '../../config/apiConfig';
import axios from 'axios';
import {getRequest} from '../../config/apiManager';


export const loginUser = (payload, callback) => async dispatch => {
  const authBaseURL = `${config.apiURL}${
    config.apiConfig.authenticationApi.loginUserHandle
  }`;
  try {
    const response = await axios.post(authBaseURL,payload);
    if (response.status === 200) {
      callback && callback(true, response, null);
      dispatch({
        type: USER_AUTH_SUCCESS,
        data: {
          response,
        },
      });
    } else {
      callback && callback(false, response, null);
    }
  } catch (error) {
    callback && callback(false, error, null);
  }
};


export const getUserDetails = callback => async dispatch => {
  const apiConfig = config.apiURL;
  const pageUrl = config.apiConfig.storeApi.getUsername;
  getRequest(apiConfig, pageUrl)
    .then(response => {
      if (response) {
        dispatch({
          type: USER_AUTH_SUCCESS,
          data: {
            response,
          },
        });
        callback && callback(true, response, null);
      } else {
        callback && callback(false, response, null);
      }
    })
    .catch(error => {
      console.log(error);
      callback && callback(false);
    });
};