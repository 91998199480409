import React from 'react';
import './CustomModal.css';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Utils from "../../../Utils/Utils";
import { colorConstants } from '../../../config/constants';


const { translate } = Utils;
class CustomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            storeIdError: false,
            errorRecreateId: false,
            defaultFormValue: [translate.yesText, translate.noText],
            formControl: {
                name: React.createRef(),
                address: React.createRef(),
                city: React.createRef(),
                zipCode: React.createRef(),
                latitude: React.createRef(),
                longitude: React.createRef(),
                storeId: React.createRef(),
                concept: React.createRef(),
                retailer: React.createRef(),
                samePriceOnlineOffline: React.createRef(),
                exportToConsumerPricingSystem: React.createRef()
            },
        };
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        const { formControl } = this.state;
        const { postStoreData, selectedItem, getListingData, handleClose, storeData } = this.props;
        this.setState({ storeIdError: false, errorRecreateId: false })
        if(formControl.storeId && formControl.storeId.current && formControl.storeId.current.value && formControl.storeId.current.value.length < 7){
            return
        }
        let exportToConsumerPricingSystem = formControl.exportToConsumerPricingSystem.current.value === translate.yesText ? true : false
        let samePriceOnlineOffline = formControl.samePriceOnlineOffline.current.value === translate.yesText ? true : false
        let payload = {
            "storeName": formControl.name.current.value,
            "longitude": formControl.longitude.current.value,
            "latitude": formControl.latitude.current.value,
            "concept": {
                "conceptId": parseInt(formControl.concept.current.value) || 1
            },
            "storeAddress": formControl.address.current.value,
            "city": formControl.city.current.value,
            "zipCode": formControl.zipCode.current.value,
            "softDelete": 0,
            "storeNumber": selectedItem.storeNumber || formControl.storeId.current.value,
            "exportToConsumerPricingSystem": exportToConsumerPricingSystem,
            "samePriceOnlineOffline": samePriceOnlineOffline,
            "exportedToS3Folders": false

        }
        if (selectedItem) {
            payload['storeId'] = selectedItem.storeId
        } else {
            try {
                storeData && storeData.forEach((item) => {
                    if (item.storeId === payload.storeId && item.softDelete) {
                        payload.oldId = payload.storeId;
                        return true;
                    }
                })
            } catch (error) {
                //
            }
        }
        postStoreData(payload, (status, response) => {
            if (status) {
                handleClose();
                getListingData();
            } else {
                if (response && response.details) {
                    this.setState({ storeIdError: true })
                    try {
                        if (response.details && response.details[0]) {
                            if (response.details[0].includes('deleted')) {
                                this.setState({ storeIdError: true, errorRecreateId: true })
                                return
                            }
                            if (response.details[0].includes('id')) {
                                this.setState({ storeIdError: true, errorRecreateId: false })
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        })
    };

    filterConceptListing(conceptListingData) {
        const { retailerListingData, selectedItem } = this.props;
        const { formControl } = this.state;
        var selectedRetail = retailerListingData[0];
        retailerListingData.forEach((item, index) => {
            if ((selectedItem.retailer && selectedItem.retailer.retailerId === item.retailerId)) {
                selectedRetail = item
            }
        })
        var updatedData = conceptListingData.filter((item) => {
            var defaultItem = (formControl.retailer.current && formControl.retailer.current.value) || selectedRetail;
            if ((item.retailer && item.retailer.retailerId) === (defaultItem.retailerId || parseInt(defaultItem))) {
                return true;
            }
            return false
        })
        return updatedData;
    }


    render() {
        const { formControl, storeIdError, errorRecreateId, defaultFormValue } = this.state
        const { showModal, handleClose, selectedItem, conceptListingData, retailerListingData } = this.props;
        return (
            <>
                <Modal dialogClassName="my-modal" show={showModal} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header className="modal-header" onHide={() => {
                        this.setState({ storeIdError: false, errorRecreateId: false })
                    }} closeButton>
                        <Modal.Title className='header'>{selectedItem ? translate.editStoreDetailsText : translate.addNewStoreText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} controlId="formGridState">
                                <Form.Label column sm={2}>{translate.retailerText}</Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }}
                                        onChange={() => {
                                            this.setState({ retailerListingData })
                                        }}
                                        required
                                        selected={(selectedItem.retailer && selectedItem.retailer.retailerName) || ''} ref={formControl.retailer} as="select">
                                        {
                                            retailerListingData.map((item, index) => {
                                                return (<option key={index} selected={(selectedItem.retailer && selectedItem.retailer.retailerId === item.retailerId) || ''} value={item.retailerId}>{item.retailerName}</option>)

                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formGridState">
                                <Form.Label column sm={2}>{translate.conceptText}</Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required selected={selectedItem.concept && selectedItem.concept.name} ref={formControl.concept} as="select">
                                        {
                                            this.filterConceptListing(conceptListingData).map((item, index) => {
                                                return (<option key={index} selected={(selectedItem.concept && selectedItem.concept.conceptId === item.conceptId) || ''} value={item.conceptId}>{item.name}</option>)
                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            {
                                selectedItem.storeNumber ?
                                    null
                                    :
                                    <Form.Group as={Row} controlId="storeId">
                                        <Form.Label column sm={2}>
                                            {translate.storeIdText}
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control style={{ width: '90%', height: 50 }} onInvalid={(test) => {
                                                test.target.setCustomValidity(translate.customErrorStoreIdText)
                                            }}
                                                isInvalid={(formControl.storeId && formControl.storeId.current && formControl.storeId.current.value && formControl.storeId.current.value.length < 7)}
                                                required ref={formControl.storeId} defaultValue={selectedItem.storeNumber}
                                                type="number" onInput={(e) => {
                                                    e.target.value = e.target.value.toString().slice(0, 7)
                                                }} placeholder={translate.storeIdText} />
                                            {
                                                storeIdError ?
                                                    <p style={{ fontSize: 14, color: colorConstants.redColor, paddingTop: 10 }}>{errorRecreateId ? translate.recreateText : translate.customErrorStoreIdExistText}</p>
                                                    : null
                                            }
                                        </Col>
                                    </Form.Group>
                            }
                            <Form.Group as={Row} controlId="name">
                                <Form.Label column sm={2}>
                                    {translate.nameText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.name} defaultValue={selectedItem.storeName || ''}
                                        type="text" placeholder={translate.nameText} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="address">
                                <Form.Label column sm={2}>
                                    {translate.addressText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.address} defaultValue={selectedItem.storeAddress || ''}
                                        type="text" placeholder={translate.addressText} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="city">
                                <Form.Label column sm={2}>
                                    {translate.cityText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.city} defaultValue={selectedItem.city || ''}
                                        type="text" placeholder="City" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="zipCode">
                                <Form.Label column sm={2}>
                                    {translate.zipCodeText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }}
                                        required
                                        ref={formControl.zipCode}
                                        defaultValue={selectedItem.zipCode || ''}
                                        type="number" placeholder={translate.zipCodeText} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="longitude">
                                <Form.Label column sm={2}>
                                    {translate.longitudeText}
                                    <div className="optional-text">{translate.optionalText}</div>
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.longitude} step="0.01"
                                        onInvalid={(test) => {
                                            test.target.setCustomValidity(translate.customErrorLongitudeText)
                                        }}
                                        defaultValue={(selectedItem.longitude)}
                                        type="number" placeholder={translate.longitudeText} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="latitude">
                                <Form.Label column sm={2}>
                                    {translate.latitudeText}
                                    <div className="optional-text">{translate.optionalText}</div>
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.latitude} step="0.01"
                                        onInvalid={(test) => {
                                            test.target.setCustomValidity(translate.customErrorLatitudeText)
                                        }}
                                        defaultValue={(selectedItem.latitude)}
                                        type="number" placeholder="Latitude" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="exportToConsumerPricingSystem">
                                <Form.Label column sm={2}>
                                    {translate.sendConsumerPriceText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }}
                                        onChange={() => {
                                            this.setState({ defaultFormValue })
                                        }}
                                        selected={selectedItem.exportToConsumerPricingSystem ? defaultFormValue[0] : defaultFormValue[1]} ref={formControl.exportToConsumerPricingSystem} as="select">
                                        {
                                            defaultFormValue.map((item, index) => {
                                                return (<option key={index} selected={selectedItem.exportToConsumerPricingSystem && item === defaultFormValue[0] ? true : !selectedItem.exportToConsumerPricingSystem  && item === defaultFormValue[1] ? true : false  } value={item}>{item}</option>)

                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="samePriceOnlineOffline">
                                <Form.Label column sm={2}>
                                    {translate.samePriceStoreText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }}
                                        onChange={() => {
                                            this.setState({ defaultFormValue })
                                        }}
                                        selected={selectedItem.samePriceOnlineOffline ? defaultFormValue[0] : defaultFormValue[1]} ref={formControl.samePriceOnlineOffline} as="select">
                                        {
                                            defaultFormValue.map((item, index) => {
                                                return (<option key={index}  selected={selectedItem.samePriceOnlineOffline && item === defaultFormValue[0] ? true : !selectedItem.samePriceOnlineOffline  && item === defaultFormValue[1] ? true : false  } value={item}>{item}</option>)

                                            })
                                        }
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} style={{ paddingTop: 10 }}>
                                <Form.Label column sm={2}>

                                </Form.Label>
                                <Col sm={2}>
                                    <Button type="submit" className="btn save-store" style={{
                                        width: 100,
                                        fontWeight: 'bold',
                                        backgroundColor: colorConstants.themeColor,
                                    }}>{translate.saveText}</Button>
                                </Col>
                                <Col sm={2} style={{ paddingLeft: 40 }}>
                                    <Button onClick={handleClose}
                                        style={{
                                            width: 100,
                                            fontWeight: 'bold',
                                            backgroundColor: colorConstants.whiteColor,
                                        }}><span style={{ color: colorConstants.themeColor }}>{translate.cancelText}</span></Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default CustomModal;