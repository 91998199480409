export const LANGUAGE = 'LANGUAGE';
export const TOKEN = 'TOKEN';
export const TEMP_TOKEN = 'TEMP_TOKEN';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const USERNAME = 'USERNAME';
export const EN = 'english';


export const Fonts = {
  ProximaNovaRegular: 'ProximaNova-Regular',
  ProximaNovaThin: 'ProximaNova-Thin',
  ProximaNovaBold: 'ProximaNova-Bold',
};

export const colorConstants = {
  themeColor: '#4f8180',
  black: '#000',
  headerBlack: '#111111',
  gradientStartColor: 'rgba(157,39,103,0.15)',
  gradientEndColor: 'rgba(235,91,13,0.21)',
  borderGreyColor: 'rgb(230, 230, 230)',
  textInputLightGreyColor: 'rgb(195,195,195)',
  darkGrey: 'rgb(92,92,92)',
  labelTextColor: 'rgb(110,90,100)',
  clearGreenColor: 'rgba(69, 153, 94, 0.8)',
  clearGreyColor: 'rgba(192, 192, 192, 0.8)',
  orangeColor: 'rgb(241, 148, 122)',
  blueHighlightColor: 'rgb(124, 164, 248)',
  whiteColor: '#fff',
  redColor: '#FF0000',
  lightBlue: '#e3edfc',
  darkBlue: '#97a9ce',
  textBlue: '#536b9b'
};

export const imageConstants = {
  globeIcon: require('../Assets/globe.svg'),
  searchIcon: require('../Assets/search.svg'),
  logoutIcon: require('../Assets/logout.svg'),
  extractIcon: require('../Assets/extract.svg'),
  axfoodLogo: require('../Assets/axfood_white.jpg'),
  axfoodBlackLogo: require('../Assets/axfood.jpg'),
  avatarIcon: require('../Assets/img_avatar.jpg'),
  barcodeActive: require('../Assets/barcodeSelected.svg'),
  barcodeIcon: require('../Assets/barcodeUnselected.svg'),
  storeMasterActive: require('../Assets/storeMasterActive.svg'),
  storeMasterIcon: require('../Assets/storeMaster.svg'),
  categoryMasterActive: require('../Assets/categoryMasterActive.svg'),
  categoryMasterIcon: require('../Assets/categoryMaster.svg'),
  productMasterIcon: require('../Assets/productMaster.svg'),
  productMasterActive: require('../Assets/productMasterActive.svg'),
  filterIcon: require('../Assets/filter.svg'),
  buttonIcon: require('../Assets/button.jpg'),
  editIcon: require('../Assets/edit.svg'),
  deleteIcon: require('../Assets/delete.svg'),
  refreshIcon: require('../Assets/refresh.svg'),
  sortArrowIcon: require('../Assets/sortArrow.svg'),
  backIcon: require('../Assets/back.svg'),
  imageIcon: require('../Assets/imageIcon.svg'),
  scrollUpIcon: require('../Assets/scrollUpIcon.png'),
  fileUploadIcon: require('../Assets/uploadFile.svg'),
  searchCancelIcon: require('../Assets/clear_search.svg'),
};
