import React, { Component } from 'react';

import LoadingBar from 'react-top-loading-bar';
import { colorConstants } from '../../config/constants';

class CustomLoader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingBarProgress: 0
        };
    }


    complete = () => {
        this.setState({ loadingBarProgress: 100 });
    };

    onLoaderFinished = () => {
        this.setState({ loadingBarProgress: 0 });
    };

    componentWillReceiveProps(nextProps){

        if (nextProps.startLoader && this.props.startLoader !== nextProps.startLoader) {
            this.setState({ loadingBarProgress: 50})
        } else{
            if(this.state.loadingBarProgress > 0){
                this.complete();
            }
        }

    }

    render() {
        return (
            <div>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={5}
                    startLoader={this.startLoader}
                    color={colorConstants.textBlue}
                    onLoaderFinished={() => this.onLoaderFinished()}
                />
            </div>
        );
    }
}

export default CustomLoader

