import React, { useState, Component } from 'react';
import { Button, Dropdown, FormControl } from 'react-bootstrap'
import './StoreMaster.css';
import Img from 'react-image';
import ReactDom from "react-dom";
import CustomModal from '../../Common/Modal/CustomModal';
import DeleteModal from '../../Common/Modal/DeleteModal/DeleteModal';
import Utils from "../../../Utils/Utils";
import Download from '../../Common/DownloadExcel';
import { uniq, map, sortBy, filter } from 'lodash';
import { imageConstants } from '../../../config/constants';
import TableSearchBar from '../../Common/SearchBar/SearchBar';
import { putStoreData, getStoreListing } from '../../../Services/Store&Product/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LocalStorageServices from '../../../Services/LocalStorage';



const { translate } = Utils;
class StoreMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            deleteModalFlag: false,
            storeData: props.storeData,
            cityFlag: false,
            samePriceOnlineOfflineFlag: false,
            exportToConsumerPricingSystemFlag: false,
            conceptFlag: false,
            sortBy: 'scanDate',
            searchFlag: false,
            filterFlag: false,
            searchValue: false,
        };
        this.selectedValue = {};

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.storeData !== prevState.storeData && !(prevState.filterFlag || prevState.searchFlag)) {
            let updatedData = nextProps.storeData.map((item) => {
                item.conceptName = item.concept && item.concept.name
                return item
            })
            return {
                storeData: updatedData
            }
        }
        return null;
    }

    componentDidMount() {
        let updatedData = this.props.storeData.map((item) => {
            item.conceptName = item.concept && item.concept.name
            return item
        })
        this.setState({ storeData: updatedData })
        ReactDom.findDOMNode(this).scrollIntoView();
    }


    handleLogout() {
        LocalStorageServices.removeAllItem();
        this.props.history.replace('/');
    }

    searchData(value) {
        if (value && value.length > 1) {
            var data = this.props.storeData.filter(item => {
                return item.storeName.toLowerCase().includes(value.toLowerCase()) || `${item.storeNumber}`.toLowerCase().includes(value.toLowerCase());
            });
            this.setState({ storeData: data, searchFlag: true, searchValue: value })
        } else {
            this.setState({ storeData: this.props.storeData, searchFlag: false, searchValue: '' })
        }
    }

    deleteStore(selectedStore) {
        const { postStoreData, getListingData } = this.props;
        selectedStore.softDelete = 1;
        postStoreData(selectedStore, (status, response) => {
            if (status) {
                this.setState({ deleteModalFlag: false })
                getListingData();
            } else {
                if (response && response.details) {
                    this.setState({ storeIdError: true })
                }
            }
        })
    }

    render() {
        const { show, storeData, cityFlag, conceptFlag, clearInput, searchValue, deleteModalFlag, exportToConsumerPricingSystemFlag,samePriceOnlineOfflineFlag } = this.state;
        return (
            <>
                <TableSearchBar clearInput={clearInput} onLogoutClick={this.handleLogout.bind(this)} onSearch={this.searchData.bind(this)} placeholderText={translate.searchByText} />
                <div className='bottom-div'>
                    <div className="d-flex flex-row justify-content-between">
                        <p className='custom-store'>{translate.storeMasterText}</p>
                        <div>
                            <Download selection={true} data={{
                                type: 'storeMasterData', value:
                                    filter(this.props.storeData, value => value.softDelete === 0)
                            }} />
                            <Button className="btn add-store" onClick={() => {
                                this.setState({ show: true })
                                this.selectedValue = false;
                            }}>
                                <Img
                                    style={{
                                        width: 25,
                                        height: 24,
                                        marginRight: 10
                                    }}

                                    src={imageConstants.buttonIcon}
                                />
                                <span className='custom-class'>{translate.addNewStoreText}</span>
                            </Button>
                        </div>

                    </div>
                    <CustomModal {...this.props}
                        showModal={show}
                        handleClose={() => {
                            this.setState({ show: false, searchFlag: false, clearInput: true }, () => {
                                setTimeout(() => {
                                    this.searchData(searchValue)
                                }, 1200)
                            })
                        }}
                        selectedItem={this.selectedValue} />
                    <DeleteModal showModal={deleteModalFlag}
                        handleClose={() => {
                            this.setState({ deleteModalFlag: false })
                        }}
                        confirmDelete={() => {
                            this.deleteStore(this.selectedValue);
                            this.selectedValue = {}
                        }}
                    />
                    <table className="table table-borderless custom-table">
                        <thead>
                            <tr className='ml-1 mr-2 mt-2'>
                                <th scope="col">{translate.storeIdText}</th>
                                <th scope="col">{translate.nameText}</th>
                                <th scope="col">
                                    <Dropdown show={conceptFlag} onToggle={() => {
                                        this.setState({ conceptFlag: !conceptFlag });
                                    }}>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            {translate.conceptText}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} >
                                            {
                                                this.props.storeData && (sortBy(uniq(map(this.props.storeData, 'conceptName')))).map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => {
                                                                var data = filter(this.props.storeData, value => value.conceptName === item);
                                                                this.setState({ storeData: data, filterFlag: true })
                                                            }} eventKey={index}>{item}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                            <div className="removeButton" onClick={() => {
                                                this.setState({ storeData: this.props.storeData })
                                                this.setState({ conceptFlag: !conceptFlag, filterFlag: false });
                                            }}>
                                                <span className="btn_apply" id="apply" >{translate.removeText}</span>
                                            </div>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </th>
                                <th scope="col">{translate.addressText}</th>
                                <th scope="col">
                                    <Dropdown show={cityFlag} onToggle={() => {
                                        this.setState({ cityFlag: !cityFlag });

                                    }}>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            {translate.cityText}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} >
                                            {
                                                this.props.storeData && (sortBy(uniq(map(storeData, 'city')))).map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => {
                                                                var data = filter(this.props.storeData, value => value.city === item);
                                                                this.setState({ storeData: data, filterFlag: true })
                                                            }}
                                                            eventKey={index}>{item}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                            <div className="removeButton" onClick={() => {
                                                this.setState({ storeData: this.props.storeData })
                                                this.setState({ cityFlag: !cityFlag, filterFlag: false });
                                            }}>
                                                <span className="btn_apply" id="apply">{translate.removeText}</span>
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown></th>
                                <th scope="col">
                                    <Dropdown show={exportToConsumerPricingSystemFlag} onToggle={() => {
                                        this.setState({ exportToConsumerPricingSystemFlag: !exportToConsumerPricingSystemFlag });

                                    }}>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            {translate.exportToStoreText}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomNoSearchMenu} className='scrollable-menu'>
                                            {
                                                this.props.storeData && (sortBy(uniq(map(storeData, 'exportToConsumerPricingSystem')))).map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => {
                                                                var data = filter(this.props.storeData, value => value.exportToConsumerPricingSystem === item);
                                                                this.setState({ storeData: data, filterFlag: true })
                                                            }}
                                                            eventKey={index}>{item ? translate.yesText : translate.noText}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                            <div className="removeButton" onClick={() => {
                                                this.setState({ storeData: this.props.storeData })
                                                this.setState({ exportToConsumerPricingSystemFlag: !exportToConsumerPricingSystemFlag, filterFlag: false });
                                            }}>
                                                <span className="btn_apply" id="apply">{translate.removeText}</span>
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown></th>
                                <th scope="col">
                                    <Dropdown show={samePriceOnlineOfflineFlag} onToggle={() => {
                                        this.setState({ samePriceOnlineOfflineFlag: !samePriceOnlineOfflineFlag });
                                    }}>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            {translate.offlineOnlineStoreText}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomNoSearchMenu} className='scrollable-menu'>
                                            {
                                                this.props.storeData && (sortBy(uniq(map(storeData, 'samePriceOnlineOffline')))).map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => {
                                                                var data = filter(this.props.storeData, value => value.samePriceOnlineOffline === item);
                                                                this.setState({ storeData: data, filterFlag: true })
                                                            }}
                                                            eventKey={index}>{item ? translate.yesText : translate.noText}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                            <div className="removeButton" onClick={() => {
                                                this.setState({ storeData: this.props.storeData })
                                                this.setState({ samePriceOnlineOfflineFlag: !samePriceOnlineOfflineFlag, filterFlag: false });
                                            }}>
                                                <span className="btn_apply" id="apply">{translate.removeText}</span>
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown></th>
                                <th scope="col">{translate.actionText}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                storeData && filter(storeData, value => value.softDelete === 0).map((item, index) => {
                                    return (
                                        <tr key={index} className='shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-2'>
                                            <td >{item.storeNumber}</td>
                                            <td >{item.storeName}</td>
                                            <td>{item.concept && item.concept.name}</td>
                                            <td >{item.storeAddress}</td>
                                            <td>{item.city}</td>
                                            <td >
                                                {
                                                    item.exportToConsumerPricingSystem ?
                                                        `${translate.yesText}`
                                                        :
                                                        `${translate.noText}`
                                                }
                                            </td>
                                            <td >
                                                {
                                                    item.samePriceOnlineOffline ?
                                                        `${translate.yesText}`
                                                        :
                                                        `${translate.noText}`

                                                }
                                            </td>
                                            <td className="action-css">
                                                <div className="Rounded-Rectangle-14 rounded cursor" onClick={() => {
                                                    this.selectedValue = item;
                                                    this.setState({ show: true })

                                                }}>
                                                    <Img decode={false} src={imageConstants.editIcon}
                                                        className="Forma-1" />
                                                </div>
                                                <div className="Rounded-Rectangle-14-delete ml-1 rounded cursor" onClick={() => {
                                                    this.setState({ deleteModalFlag: true })
                                                    this.selectedValue = item;
                                                }}>
                                                    <Img decode={false} src={imageConstants.deleteIcon}
                                                        className="Forma-1-delete " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        className='custom-header cursor'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <Img
            style={{
                width: 12,
                marginLeft: 5
            }}
            decode={false}
            src={imageConstants.filterIcon}
        />
    </span>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'Montserrat': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto font-13"
                    placeholder={translate.typeToFilterText}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled">
                    <div className='scrollable-menu'>
                        {React.Children.toArray(children[0])
                            .filter(
                                (child) =>
                                    !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                            )
                        }
                    </div>
                    {React.Children.toArray(children[1])}
                </ul>
            </div>

        );
    },
);

const CustomNoSearchMenu = React.forwardRef(
    ({ children, style, className, 'Montserrat': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    <div className='scrollable-menu'>
                        {React.Children.toArray(children[0])
                            .filter(
                                (child) =>
                                    !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                            )
                        }
                    </div>
                    {React.Children.toArray(children[1])}
                </ul>
            </div>

        );
    },
);



const mapStateToProps = state => ({
    conceptListingData: state.storeReducer.conceptDataArray,
    retailerListingData: state.storeReducer.retailerDataArray
});

const mapDispatchToProps = dispatch => ({
    postStoreData: bindActionCreators(putStoreData, dispatch),
    getListingData: bindActionCreators(getStoreListing, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoreMaster));
