import React from 'react';
import './ConfirmModal.css';
import '../CustomModal.css';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Utils from "../../../../Utils/Utils";
import { colorConstants } from '../../../../config/constants';


const { translate, ActivityIndicator } = Utils;
class ConfirmModal extends React.Component {
    handleSubmit = event => {
        const { confirmDelete } = this.props;
        event.preventDefault();
        event.stopPropagation();
        confirmDelete && confirmDelete();
    };


    render() {
        const { showModal, handleClose, reject, errorMessage, activityStartFlag } = this.props;
        return (
            <>
                <Modal dialogClassName="delete-modal" show={showModal} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title className='header'>{translate.confirmText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            activityStartFlag ?
                                <ActivityIndicator
                                    style={{
                                        marginLeft: "35%",
                                        alignSelf: "center",
                                        height: 100,
                                        width: 100
                                    }} />
                                :
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={10} style={{ marginLeft: 30 }}>
                                            {reject ? translate.confirmApproveAll : translate.confirmApproveMarked}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} style={{ paddingTop: 10 }}>
                                        <Form.Label column sm={2}>

                                        </Form.Label>
                                        <Col sm={2} style={{ marginRight: 50 }}>
                                            <Button onClick={handleClose}
                                                style={{
                                                    width: 100,
                                                    fontWeight: 'bold',
                                                    backgroundColor: colorConstants.whiteColor,
                                                }}><span style={{ color: colorConstants.themeColor }}>{translate.noText}</span></Button>
                                        </Col>
                                        <Col sm={2}>
                                            <Button type="submit" className="btn save-store" style={{
                                                width: 100,
                                                fontWeight: 'bold',
                                                backgroundColor: colorConstants.themeColor,
                                            }}>{translate.yesCapText}</Button>
                                        </Col>
                                    </Form.Group>
                                    {
                                        errorMessage ?
                                            <Form.Label style={{ fontSize: 16, color: colorConstants.redColor, paddingLeft: 40 }} column sm={12}>
                                                {translate.noMarkedArticles}
                                            </Form.Label>
                                            :
                                            null
                                    }
                                </Form>
                        }
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default ConfirmModal;