import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import authenticationReducer from './Authentication/reducer';
import storeReducer from './Store&Product/reducer'

const reducer = combineReducers({authenticationReducer,storeReducer});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
