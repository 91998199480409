import {USER_AUTH_SUCCESS} from './constants';

const initialState = {
  userAuthData: '',
};

const authenticationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_AUTH_SUCCESS:
      return {...state, userAuthData: action.data};
    default:
      return state;
  }
};

export default authenticationReducer;
