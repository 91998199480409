import React from 'react';
import '../CustomModal.css';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { postProductsApprovalStatus } from '../../../../Services/Store&Product/action';
import { colorConstants } from '../../../../config/constants';
import Utils from '../../../../Utils/Utils';

const { translate, convertPrice } = Utils;



class CustomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedItem: props.selectedItem,
            formControl: {
                uom: React.createRef(),
                price: React.createRef(),
                discountedPrice: React.createRef(),
                discountedUom: React.createRef(),
                discountedType: React.createRef(),
                discountedQuantity: React.createRef(),
            },
        };
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        const { formControl, selectedItem } = this.state;
        const { saveProductDataStatus, handleClose } = this.props;
        let item = {}
        item.status = 'Pending';
        item.detailsId = JSON.stringify(selectedItem.id);
        item.barcodeId = selectedItem.product && selectedItem.product.barcodeId;
        item.productId = selectedItem.product && selectedItem.product.productId;
        item.productName = selectedItem.product && selectedItem.product.productName;
        item.volume = selectedItem.volume;
        item.discountedUnit = formControl.discountedUom.current.value !== translate.chooseText ? formControl.discountedUom.current.value : selectedItem.discountedUnit;
        item.discountedQuantity = formControl.discountedQuantity.current.value || 0;
        item.barcoded = selectedItem.barcoded;
        item.discount = null;
        item.text= selectedItem.text;
        if (formControl.discountedType.current.value !== translate.chooseText) {
            item.discount = {
                discountId:
                    formControl.discountedType.current.value
            }
        }
        item.userName = selectedItem.userName;
        item.forceSave = false;
        item.unitName = formControl.uom.current.value !== translate.chooseText ? formControl.uom.current.value :
            (selectedItem.measurementUnit && selectedItem.measurementUnit.unitName);
        item.discountedPriceForAnyLanguage = formControl.discountedPrice.current.value || 0;
        item.store = {
            storeId: selectedItem.store && selectedItem.store.storeId
        }
        item.priceForAnyLanguage = formControl.price.current.value;
        item.imageUrl = selectedItem.imageUrl
        item.timeOfTransaction = null;
        saveProductDataStatus([item], (status, response) => {
            if (status) {
                handleClose();
            }
        })
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            selectedItem: nextProps.selectedItem
        }
    }


    render() {
        const { formControl, selectedItem } = this.state
        const { showModal, handleClose, quantityUnitData, discountData } = this.props;
        console.log('ASHISHNEGI', this.state.selectedItem.text);
        return (
            <>
                <Modal dialogClassName="my-modal" show={showModal} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header className="modal-header" closeButton>
                        <Modal.Title className='header'>{selectedItem && selectedItem.product && selectedItem.product.productName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} controlId="name">
                                <Form.Label column sm={3} style={{ color: colorConstants.black }}>
                                    {translate.regularPriceText}
                                </Form.Label>
                            </Form.Group>
                            <div style={{ marginLeft: 20 }}>
                                <Form.Group as={Row} controlId="UoM">
                                    <Form.Label column sm={2}>
                                        {translate.uomText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.uom} as="select">
                                            {
                                                quantityUnitData.map((item, index) => {
                                                    return (<option key={index}
                                                        selected={((selectedItem.measurementUnit && selectedItem.measurementUnit.unitName) === item.unitName) || ''}
                                                        value={item.unitName}>{item.unitName.toUpperCase()}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="Price">
                                    <Form.Label column sm={2}>
                                        {translate.priceText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.price} defaultValue={convertPrice(selectedItem.price) || ''}
                                            type={translate._language === 'sw' ? "string" : "number" } step="0.01" placeholder={translate.priceText} />
                                    </Col>
                                </Form.Group>
                            </div>
                            <Form.Group as={Row} controlId="name">
                                <Form.Label column sm={4} style={{ color: colorConstants.black }}>
                                    <span>{translate.discountedPriceText}</span> <span className='reset-form' onClick={() => {
                                        selectedItem.discountedUnit = null;
                                        formControl.discountedPrice.current.value = null;
                                        formControl.discountedQuantity.current.value = null;
                                        selectedItem.discount = null;
                                        this.setState({ selectedItem })
                                    }}>  {translate.resetText} </span>
                                </Form.Label>

                            </Form.Group>
                            <div style={{ marginLeft: 20 }}>
                                <Form.Group as={Row} controlId="discountedQuantity">
                                    <Form.Label column sm={2}>
                                        {translate.quantityText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.discountedQuantity} defaultValue={selectedItem.discountedQuantity || ''}
                                            type="number" step="1" placeholder={translate.quantityText} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="discountedUoM">
                                    <Form.Label column sm={2}>
                                        {translate.uomText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.discountedUom} as="select">
                                            {
                                                (!selectedItem.discountedUnit) ?
                                                    <option selected>{translate.chooseText}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                quantityUnitData.map((item, index) => {
                                                    return (<option key={index}
                                                        selected={(selectedItem.discountedUnit === item.unitName) || ''}
                                                        value={item.unitName}>{item.unitName.toUpperCase()}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="discountedPrice">
                                    <Form.Label column sm={2}>
                                        {translate.priceText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.discountedPrice} defaultValue={convertPrice(selectedItem.discountedPrice) || ''}
                                            type={translate._language === 'sw' ? "string" : "number" } step="0.01" placeholder={translate.priceText} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="Type">
                                    <Form.Label column sm={2}>
                                        {translate.typeText}
                                    </Form.Label>
                                    <Col sm={8}>
                                        <Form.Control style={{ width: '90%', height: 50 }} ref={formControl.discountedType} as="select">
                                            {
                                                (!selectedItem.discount) ?
                                                    <option selected>{translate.chooseText}</option>
                                                    :
                                                    null
                                            }
                                            {
                                                discountData.map((item, index) => {
                                                    return (<option key={index}
                                                        selected={((selectedItem.discount && selectedItem.discount.discountId) == item.discountId) || ''}
                                                        value={item.discountId}>{item.name}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </div>
                            <Form.Group as={Row} style={{ paddingTop: 10 }}>
                                <Form.Label column sm={2}>

                                </Form.Label>
                                <Col sm={2}>
                                    <Button type="submit" className="btn save-store" style={{
                                        width: 100,
                                        fontWeight: 'bold'
                                    }}>{translate.saveText}</Button>
                                </Col>
                                <Col sm={2} style={{ paddingLeft: 40 }}>
                                    <Button onClick={() => {
                                        this.setState({ errorFlag: false })
                                        handleClose()
                                    }}
                                        style={{
                                            width: 100,
                                            fontWeight: 'bold',
                                            backgroundColor: colorConstants.whiteColor,
                                        }}><span style={{ color: colorConstants.themeColor }}>{translate.cancelText}</span></Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    quantityUnitData: state.storeReducer.quantityUnitData,
    discountData: state.storeReducer.discountData,
});

const mapDispatchToProps = dispatch => ({
    saveProductDataStatus: bindActionCreators(postProductsApprovalStatus, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomModal));