import React, { Component, useState } from 'react';
import ReactDom from "react-dom";
import { Button, Form, Dropdown } from 'react-bootstrap'
import './ScanProducts.css';
import Img from 'react-image';
import CustomModal from '../../../Common/Modal/ScanMasterModal/ScanMasterModal';
import DeleteModal from '../../../Common/Modal/DeleteModal/DeleteModal';
import {
    postProductsApprovalStatus,
    getScanProductList,
    filterScannedProduct,
    getScanProductListExtract,
    getScanProductListExtractNew,
} from '../../../../Services/Store&Product/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import CustomLoader from '../../../Loader/Loader';
import Utils from '../../../../Utils/Utils';
import Download from '../../../Common/DownloadExcel';
import moment from "moment";
import { imageConstants, colorConstants, LANGUAGE } from '../../../../config/constants';
import ConfirmModal from '../../../Common/Modal/ConfirmModal/ConfirmModal';
import LocalStorageServices from '../../../../Services/LocalStorage';

const { translate, convertPrice, convertStatus, ActivityIndicator } = Utils;
const MAX_LENGTH = 20;


class ScanProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            deleteModalFlag: false,
            soloDeleteModalFlag: false,
            confirmModalFlag: false,
            defaultFlag: true,
            approveFlag: false,
            deleteFlag: false,
            activityStartFlag: false,
            productMasterData: this.updateProductMasterData(props.productMasterData),
            prevPropProductMasterData: this.updateProductMasterData(props.productMasterData),
            productMasterDataExtract: null,
            productMasterDataExtractNew: null,
            productMasterDataExtractNewStatus: false,
            storeName: '',
            storeId: null,
            transactionDate: null,
            pageSize: 100,
            pageNumber: 1,
            loadMoreFlag: true,
            filterType: 'status',
            conceptFlag: false,
            errorMessageModal: false,
            errorRejectMessageModal: false,
            status: 'all',
            selectedLanguage: false,
            productIndex: null,
            showArticleName: false,
            showFullText: {},
            showComparableFullText: {},
        };
        this.selectedValue = {}
        this.selectedArray = []
    }
    getTime(date) {
        return moment(date).format("HH:mm");
    }

    getDescriptionText = (item, index) => {
        const { showFullText } = this.state;

        if (item && item.text) {
            if (item.text.length > MAX_LENGTH && !showFullText[index]) {
                return item.text.slice(0, MAX_LENGTH) + '.....';
            }
            return item.text;
        }
        return '';
    };

    toggleMoreText = (index) => {
        this.setState((prevState) => ({
            showFullText: {
                ...prevState.showFullText,
                [index]: !prevState.showFullText[index]
            }
        }));
    };

    toggleLessText = (index) => {
        this.setState((prevState) => ({
            showFullText: {
                ...prevState.showFullText,
                [index]: false
            }
        }));
    };

    getComparableText = (item, index) => {
        const { showComparableFullText } = this.state;

        if (item.product && item.product.comparableDescription) {
            if (item.product.comparableDescription.length > MAX_LENGTH && !showComparableFullText[index]) {
                return item.product.comparableDescription.slice(0, MAX_LENGTH) + '.....';
            }
            return item.product.comparableDescription;
        }
        return '';
    };

    toggleComparableMoreText = (index) => {
        this.setState((prevState) => ({
            showComparableFullText: {
                ...prevState.showComparableFullText,
                [index]: !prevState.showComparableFullText[index]
            }
        }));
    };

    toggleComparableLessText = (index) => {
        this.setState((prevState) => ({
            showComparableFullText: {
                ...prevState.showComparableFullText,
                [index]: false
            }
        }));
    };

    handleShow() {
        const { modalShow } = this.state;
        this.setState((prevState) => ({
            modalShow: !prevState.modalShow,

        }));
        if (modalShow) {
            this.setState({
                productMasterData: [],
                pageNumber: 0,
            }, () => {
                this.refreshData()
                this.getScanProductsExtract()
            })
        }
    }


    getStoreName() {
        const { productMasterData, selectedStore } = this.props;
        const { pageSize } = this.state;
        try {
            var storeName = selectedStore.name;
            var storeId = selectedStore.id;
            var transactionDate = selectedStore.scanDate;
            storeName = `${storeName} (${transactionDate})`;
            this.setState({
                storeName, storeId, transactionDate, loadMoreFlag: productMasterData.length < pageSize ? false : true
            });
        } catch (error) {
            //
        }
    }


    updateProductMasterData(data) {
        data && data.length && data.forEach((item) => {
            item.selectedFlag = item.status === 'Pending' ? true : false
        })
        var updatedProductMasterList = [...data];
        return updatedProductMasterList;
    }

    handleChange(event) {
        const { productMasterData } = this.state;
        let updatedMasterData = productMasterData.map((item) => {
            if (item.status === 'Pending') {
                event.target.checked = event.target.checked
            }
            else {
                event.target.checked = false
            }
            item.selectedFlag = event.target.checked
            return item
        })
        this.setState((prevState) => ({
            defaultFlag: !prevState.defaultFlag,
            productMasterData: updatedMasterData
        }));
    }

    refreshData(customStatus) {
        const { getScanProductList, selectedStore, refreshPrevData, onSearch } = this.props;
        const { filterType, status, pageSize, pageNumber, prevSearchParam } = this.state;
        var payload = {
            storeId: selectedStore.storeId,
            scanDate: selectedStore.scanDate,
            pageSize: pageSize,
            pageNumber: pageNumber,
            status: customStatus || status,
            filterType: filterType,
            source: selectedStore.source,
        }
        if (prevSearchParam && prevSearchParam.length) {
            onSearch && onSearch(prevSearchParam);
            return
        }
        getScanProductList(payload, (status, response) => {
            this.getScanProductsExtract();
            if (status) {
                if (!response.length) {
                    this.setState({ loadMoreFlag: false })
                }
                this.setState({
                    pageNumber: pageNumber + 1,
                }, () => {
                    refreshPrevData();
                })
            }
        })
    }

    createPayload(value) {
        let item = {}
        item.status = value.status;
        item.detailsId = value.id;
        item.text = value.text;
        item.barcodeId = value.product && value.product.barcodeId;
        item.productId = value.product && value.product.productId;
        item.productName = value.product && value.product.productName;
        item.volume = value.volume;
        item.discount = value.discount;
        item.discountedUnit = value.discountedUnit;
        item.discountedQuantity = value.discountedQuantity;
        item.barcoded = value.barcoded;
        item.discount = null;
        if (value.discount && value.discount.discountId) {
            item.discount = {
                discountId: value.discount.discountId
            };
        }
        item.userName = value.userName;
        item.forceSave = false;
        item.unitName = value.measurementUnit && value.measurementUnit.unitName;
        item.discountedPriceForAnyLanguage = value.discountedPrice || null;
        item.store = {
            storeId: value.store && value.store.storeId
        }
        item.priceForAnyLanguage = value.price;
        item.imageUrl = value.imageUrl
        item.timeOfTransaction = null;
        return item;
    }

    approveScans() {
        this.setState({ activityStartFlag: true })
        this.getScanProductsExtract(() => {
            const { saveProductDataStatus, } = this.props;
            const { productMasterDataExtract, productMasterData, approveFlag } = this.state;
            let data = approveFlag ? productMasterDataExtract : productMasterData
            var payload = []
            data && data.forEach((value) => {
                var item = this.createPayload(value);
                if (item.status.toLowerCase() === 'pending') {
                    if (!approveFlag) {
                        if (value.selectedFlag) {
                            item.status = 'Approved';
                            payload.push(item);
                        }
                    } else {
                        item.status = 'Approved';
                        payload.push(item);
                    }
                }
            });
            if (payload && payload.length) {
                saveProductDataStatus(payload, (status, response) => {
                    if (status) {
                        this.setState({
                            productMasterData: [], pageNumber: 0, confirmModalFlag: false, approveFlag: false,
                            errorMessageModal: false, activityStartFlag: false
                        }, () => {
                            this.refreshData()
                        })
                    }
                })
            } else {
                this.setState({
                    errorMessageModal: true,
                    activityStartFlag: false
                })
            }
        });
    }

    rejectItem(value) {
        const { saveProductDataStatus } = this.props;
        var payload = this.createPayload(value);
        payload.status = 'Rejected'
        saveProductDataStatus([payload], (status, response) => {
            if (status) {
                this.setState({ productMasterData: [], pageNumber: 0 }, () => {
                    this.refreshData();
                })
                this.setState({ deleteModalFlag: false })
            }
        })
    }

    rejectMarkedItems() {
        this.getScanProductsExtract(() => {
            const { saveProductDataStatus, } = this.props;
            const { productMasterDataExtract, productMasterData, deleteFlag } = this.state;
            let data = deleteFlag ? productMasterDataExtract : productMasterData
            var payload = []
            data && data.forEach((value) => {
                var item = this.createPayload(value);
                if (item.status.toLowerCase() === 'pending') {
                    if (!deleteFlag) {
                        if (value.selectedFlag) {
                            item.status = 'Rejected';
                            payload.push(item);
                        }
                    }
                }
            });
            if (payload && payload.length) {
                saveProductDataStatus(payload, (status, response) => {
                    if (status) {
                        this.setState({
                            productMasterData: [], pageNumber: 0, deleteModalFlag: false, deleteFlag: false, errorRejectMessageModal: false
                        }, () => {
                            this.refreshData()
                        })
                    }
                })
            } else {
                this.setState({
                    errorRejectMessageModal: true,
                })
            }
        });
    }

    getClass(item) {
        return 'custom-font-size pending';
    }

    checkPointerClass(item) {
        return item.status === 'Pending' ? '' : 'no-clicks';
    }

    getScanProductsExtract(callback) {
        const { selectedStore } = this.props;
        var payload = {
            storeId: selectedStore.storeId,
            scanDate: selectedStore.scanDate,
            source: selectedStore.source,
        }
        this.props.getScanProductDataExtract(payload, (status, response) => {
            if (status) {
                this.setState({
                    productMasterDataExtract: response,
                }, () => callback && callback())
            }
        })
    }

    getScanProductsExtractNew(callback) {
        const { selectedStore } = this.props;
        var payload = {
            storeId: selectedStore.storeId,
            scanDate: selectedStore.scanDate,
            source: selectedStore.source,
        }
        this.props.getScanProductDataExtractNew(payload, (status, response) => {
            if (status) {
                this.setState({
                    productMasterDataExtractNew: response,
                    productMasterDataExtractNewStatus: status,
                }, () => callback && callback())
            }
        })
    }


    static getDerivedStateFromProps(props, state) {
        if (props.resetData) {
            return {
                productMasterData: []
            }
        }
        if (props.productMasterData !== state.prevPropProductMasterData
        ) {
            props.productMasterData.forEach((item) => {
                item.selectedFlag = (item.status === 'Pending') ? true : false
            })
            let productMasterData = [...state.productMasterData, ...props.productMasterData];
            if (props.resetData) {
                productMasterData = [...props.productMasterData];
            }
            if (props.searchParam !== state.prevSearchParam) {
                productMasterData = [...props.productMasterData]
            }
            return {
                productMasterData: productMasterData,
                prevPropProductMasterData: props.productMasterData,
                prevSearchParam: props.searchParam,
                loadMoreFlag: props.productMasterData.length < state.pageSize ? false : true
            }
        }
        return null
    }

    openAllUrls = (urls) => {
        urls.map((url) => {
            window.open(url, '_blank');
        });
    };

    render() {
        const { modalShow,
            defaultFlag,
            activityStartFlag,
            loadMoreFlag,
            deleteModalFlag,
            soloDeleteModalFlag,
            productMasterData,
            productMasterDataExtract,
            productMasterDataExtractNew,
            productMasterDataExtractNewStatus,
            conceptFlag,
            selection,
            approveFlag,
            confirmModalFlag,
            errorMessageModal,
            errorRejectMessageModal,
            selectedLanguage,
            status,
            showFullText,
            showComparableFullText,
            storeName } = this.state;
        const { searchParam } = this.props;

        return (
            <div>
                <div className="d-flex flex-row justify-content-between">

                    <p className='custom-store cursor' onClick={() => {
                        window.location.reload();
                    }}>
                        <Img decode={false} style={{ width: 25, marginRight: 20 }} src={imageConstants.backIcon} />
                        {storeName}
                    </p>

                    <div>
                        {productMasterDataExtractNewStatus
                            ?
                            <div style={{ marginBottom : 10 }}>
                                    <Download selection={true} data={{ type: 'scanData', value: productMasterDataExtractNew }} ref={(ref) => (this.childComponentRef = ref)} />
                            </div>
                            :
                            <div style={{ marginBottom : 10 }}>
                                <ActivityIndicator
                                    style={{
                                        alignSelf: "center",
                                        height: 20,
                                        width: 20
                                    }} />
                                <Download selection={false} data={{ type: 'scanData' }} />
                            </div>
                            
                        }
                        <Button className="btn add-store" onClick={() => {
                            this.setState({ confirmModalFlag: true })
                        }}>
                            <span className='custom-class'>{translate.approveMarkedText}</span>
                        </Button>
                        {
                            // console.log("ashihs313",productMasterDataExtractNewStatus)
                        }
                    </div>

                </div>
                <div className='div-container'>
                    <>
                        <Form>
                            {['radio'].map((type) => (
                                <div key={`inline-${type}`}
                                    onClick={(event) => {
                                        if (event.target.id === '2') {
                                            this.setState({
                                                selection: event.target.id,
                                                productMasterData: [],
                                                pageNumber: 0,
                                                filterType: 'statusAndimage'
                                            }, () => {
                                                this.refreshData();
                                            })
                                            if (selection === event.target.id) {
                                                this.setState({
                                                    selection: null,
                                                    productMasterData: [],
                                                    pageNumber: 0,
                                                    filterType: 'status'
                                                })
                                            }
                                        }
                                        else {
                                            if (event.target.id === '1') {
                                                if (selection === event.target.id) {
                                                    this.setState({
                                                        selection: null,
                                                        productMasterData: [],
                                                        pageNumber: 0,
                                                        filterType: 'status'
                                                    }, () => {
                                                        this.refreshData();
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        selection: event.target.id,
                                                        productMasterData: [],
                                                        pageNumber: 0,
                                                        filterType: 'statusAndduplicates'
                                                    }, () => {
                                                        this.refreshData();
                                                    })
                                                }
                                            }
                                        }
                                    }}>
                                    <Form.Check checked={selection === '1'} name="selection" inline label={translate.articleScanText} type={type} id={'1'} />
                                    <Form.Check checked={selection === '2'} name="selection" inline label={translate.articleImageText} type={type} id={'2'} />
                                </div>
                            ))}
                        </Form>
                        {/* <div className='div-container-bottom'>
                            <Button className="btn add-store custom-width" onClick={() => {
                                this.setState({ confirmModalFlag: true, approveFlag: true })
                            }}>
                                <span className='custom-class'>{translate.approveAllText}</span>
                            </Button>
                        </div> */}
                        <div className='div-container-bottom'>
                            <div className='div-container-bottom-right'>

                                <Button className="btn add-store" onClick={() => {
                                    this.setState({ deleteModalFlag: true, showArticleName: false, soloDeleteModalFlag: false })
                                }}>
                                    <span className='custom-class'>{translate.rejectMarkedText}</span>
                                </Button>

                            </div>

                            <Button className="btn add-store custom-width" onClick={() => {
                                this.setState({ confirmModalFlag: true, approveFlag: true })
                            }}>
                                <span className='custom-class'>{translate.approveAllText}</span>
                            </Button>
                        </div>
                    </>
                </div>

                <table className="table table-borderless table-custom-header sticky">
                    <span class="float" onClick={() => {
                        ReactDom.findDOMNode(this).scrollIntoView();
                    }}>
                        <i class="fa fa-plus my-float">
                            <Img
                                style={{
                                    width: 25,
                                    marginTop: 6,
                                }}
                                decode={false}
                                src={imageConstants.scrollUpIcon}
                            />
                        </i>
                    </span>
                    <thead className='custom-thead'>
                        <tr className='ml-1 mr-2 mt-2'>

                            <th className='sticky' scope="col">
                                <label className="container">
                                    <input type="checkbox"
                                        checked={defaultFlag}
                                        onChange={(event) => this.handleChange(event)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </th>
                            <th className='sticky' scope="col">{translate.gtinCapsTEXT}</th>
                            <th className='sticky' scope="col">{translate.articleNameText}</th>
                            <th className='sticky' scope="col">{translate.DescriptionText}</th>
                            <th className='sticky' scope="col" colSpan="2">{translate.regularPriceText}</th>
                            <th className='sticky' scope="col" colSpan="4">{translate.discountedPriceText}</th>
                            <th className='sticky' scope="col" colSpan="2">{translate.comparableGTINText}</th>
                            <th className='sticky' scope="col">{translate.timeText}</th>
                            <th className='sticky' scope="col">{translate.scannedByText}</th>
                            <th className='sticky' scope="col">{translate.photoText}</th>
                            <th className='sticky' scope="col" colSpan="2">

                                <Dropdown show={conceptFlag} onToggle={() => {
                                    this.setState({ conceptFlag: !conceptFlag });
                                }}>
                                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                        {translate.statusText}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu alignRight={true} as={CustomMenu}>
                                        {
                                            ['Approved', 'Pending', 'Rejected', 'All'].map((item, index) => {
                                                return (
                                                    <Dropdown.Item key={index}
                                                        style={{ paddingBottom: 5 }}
                                                        onClick={() => {
                                                            this.setState({
                                                                status: item,
                                                                productMasterData: [],
                                                                pageNumber: 0,
                                                            }, () => {
                                                                this.refreshData();
                                                            })
                                                        }} eventKey={index}>
                                                        <div style={{
                                                            padding: 5,
                                                            backgroundColor: (status.toLowerCase() === item.toLowerCase()) ? colorConstants.textBlue : null,
                                                            color: (status.toLowerCase() === item.toLowerCase()) ? colorConstants.whiteColor : null
                                                        }}>

                                                            {convertStatus(item)}
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                        <div className="bottomView" />
                                    </Dropdown.Menu>

                                </Dropdown>


                            </th>
                            <th className='sticky' scope="col">{translate.actionText}</th>
                        </tr>
                        <tr className='ml-1 mr-2 mt-2'>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color' colSpan="1">{translate.uomText}</th>
                            <th className='custom-color' colSpan="1">{translate.priceText}</th>
                            <th className='custom-color' colSpan="1">{translate.quantityText}</th>
                            <th className='custom-color' colSpan="1">{translate.uomText}</th>
                            <th className='custom-color' colSpan="1">{translate.priceText}</th>
                            <th className='custom-color' colSpan="1">{translate.typeText}</th>
                            <th className='custom-color' colSpan="1">{translate.comparableGtinCapsTEXT}</th>
                            <th className='custom-color' colSpan="1">{translate.comparableDescriptionText}</th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                            <th className='custom-color'></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            productMasterData && productMasterData.length ?
                                productMasterData.map((item, index) => {
                                    return (
                                        <tr key={index} className='approved shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-2'>
                                            <td className={this.getClass(item)} >
                                                <label className="container">
                                                    <input type="checkbox"
                                                        checked={item.status === 'Pending' ? item.selectedFlag : false}
                                                        disabled={item.status === 'Pending' ? false : true}
                                                        onChange={(event) => {
                                                            item.selectedFlag = event.target.checked
                                                            const length = productMasterData.filter((item) => item.selectedFlag).length;
                                                            const statusLength = productMasterData.filter((item) => item.status === 'Approved' || item.status === 'Rejected').length;
                                                            if (event.target.checked === false) {
                                                                this.setState({ defaultFlag: false })
                                                            } else if (statusLength > 0 ? productMasterData.length - statusLength === length : productMasterData.length === length && event.target.checked === true) {
                                                                this.setState({ defaultFlag: true })
                                                            }
                                                            this.setState({ productMasterData })
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td className={this.getClass(item)}>{(item.product && item.product.barcodeId) || ''}</td>
                                            <td className={this.getClass(item)}>{(item.product && item.product.productName) || ''}</td>
                                            <td className={`${this.getClass(item)} ${showFullText[index] ? 'multiline' : ''}`} key={index}>
                                                {this.getDescriptionText(item, index)}
                                                {item && item.text && item.text.length > MAX_LENGTH && !showFullText[index] && (
                                                    <span className="clickable-text" onClick={() => this.toggleMoreText(index)}> {translate.moreText}</span>
                                                )}
                                                {item && item.text && item.text.length > MAX_LENGTH && showFullText[index] && (
                                                    <span className="clickable-text" onClick={() => this.toggleLessText(index)}> {translate.lessText}</span>
                                                )}
                                            </td>
                                            <td className={this.getClass(item)} style={{
                                                textTransform: 'uppercase'
                                            }}>{item.measurementUnit && item.measurementUnit.unitName}</td>
                                            <td className={this.getClass(item)}>{convertPrice(item.price)|| ''}</td>
                                            <td className={this.getClass(item)}>{item.discountedQuantity || ''}</td>
                                            <td className={this.getClass(item)} style={{
                                                textTransform: 'uppercase'
                                            }}>{item.discountedUnit || ''}</td>
                                            <td className={this.getClass(item)}>{convertPrice(item.discountedPrice) || ''}</td>
                                            <td className={this.getClass(item)}>{selectedLanguage ? (item.discount && item.discount.nameInEnglish) : (item.discount && item.discount.name)}</td>
                                            <td className={this.getClass(item)}>{item.product && item.product.comparableGtin || ''}</td>
                                            <td className={`${this.getClass(item)} ${showComparableFullText[index] ? 'multiline' : ''}`} key={index}>
                                                {this.getComparableText(item, index)}
                                                {item.product && item.product.comparableDescription && item.product.comparableDescription.length > MAX_LENGTH && !showComparableFullText[index] && (
                                                    <span className="clickable-text" onClick={() => this.toggleComparableMoreText(index)}> {translate.moreText}</span>
                                                )}
                                                {item.product && item.product.comparableDescription && item.product.comparableDescription.length > MAX_LENGTH && showComparableFullText[index] && (
                                                    <span className="clickable-text" onClick={() => this.toggleComparableLessText(index)}> {translate.lessText}</span>
                                                )}
                                            </td>
                                            {/* <td className={this.getClass(item)}>{item.product && item.product.comparableDescription || ''}</td> */}
                                            <td className={this.getClass(item)}>{this.getTime(item.transactionDate)}</td>
                                            <td className={this.getClass(item)}>{(item.userName && item.userName.substring(item.userName.lastIndexOf("_") + 1, item.userName.lastIndexOf("@"))) || item.userName}</td>
                                            {
                                                item?.imageUrl?.length > 0 ?
                                                    <td className={this.getClass(item)}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                width: '70px',
                                                                height: '45px',
                                                            }}
                                                        >
                                                            <a href={item?.imageUrl[0]} target='_blank'>
                                                                <Img
                                                                    style={{
                                                                        width: 35,
                                                                        marginRight: 7,
                                                                    }}
                                                                    decode={false}
                                                                    src={imageConstants.imageIcon}
                                                                />
                                                            </a>
                                                            {item?.imageUrl?.length > 1 && (
                                                                <a href="#" onClick={() => this.openAllUrls(item?.imageUrl?.slice(1))} style={{ marginTop: 12, fontSize: 17, fontWeight: 'bold' }}>
                                                                    +{item?.imageUrl?.length - 1}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </td>
                                                    :
                                                    <td className={this.getClass(item)}></td>
                                            }
                                            <td className={this.getClass(item)} colSpan="2">{(item.status && convertStatus(item.status)) || ''}</td>

                                            <td className={`${this.getClass(item)} action-css`}>
                                                <div className={`Rounded-Rectangle-14 rounded cursor${this.checkPointerClass(item)}`} onClick={() => {
                                                    this.handleShow()
                                                    this.selectedValue = item;
                                                }}>
                                                    <Img decode={false} src={imageConstants.editIcon}
                                                        className="Forma-1" />
                                                </div>
                                                <div className={`Rounded-Rectangle-14-delete ml-1 rounded cursor${this.checkPointerClass(item)}`} onClick={() => {
                                                    this.setState({ deleteModalFlag: (item.status === 'Approved' || item.status === 'Rejected') ? false : true, showArticleName: true, soloDeleteModalFlag: true })
                                                    this.setState({ productIndex: index })
                                                    this.selectedValue = item;
                                                }}>
                                                    <Img decode={false} src={imageConstants.deleteIcon}
                                                        className="Forma-1-delete " />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                        {
                            (loadMoreFlag && !searchParam) ?
                                <span
                                    onClick={() => {
                                        this.refreshData();
                                    }}
                                    style={{
                                        color: colorConstants.darkBlue,
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}>{translate.loadMoreText}</span>
                                :
                                null
                        }
                    </tbody>
                </table>
                {
                    <CustomModal
                        showModal={modalShow}
                        handleClose={this.handleShow.bind(this)}
                        selectedItem={this.selectedValue}
                    />
                }
                <DeleteModal
                    reject={true}
                    showModal={deleteModalFlag}
                    errorRejectMessage={errorRejectMessageModal}
                    handleClose={() => {
                        this.setState({ deleteModalFlag: false, errorRejectMessageModal: false })
                        // this.getScanProductDataExtract()
                        this.getScanProductsExtract()
                    }}
                    confirmDelete={() => {
                        if (soloDeleteModalFlag) {
                            this.rejectItem(this.selectedValue);
                            this.selectedValue = {}
                        } else {
                            this.rejectMarkedItems();
                        }

                    }}
                    productIndex={this.state.productIndex}
                    showArticleName={this.state.showArticleName}
                />
                <ConfirmModal
                    reject={approveFlag}
                    showModal={confirmModalFlag}
                    errorMessage={errorMessageModal}
                    activityStartFlag={activityStartFlag}
                    handleClose={() => {
                        this.setState({ confirmModalFlag: false, approveFlag: false, errorMessageModal: false })
                    }}
                    confirmDelete={() => {
                        this.approveScans()
                    }}
                />
            </div >
        )
    }

    componentDidMount() {
        this.getStoreName();
        // this.getScanProductsExtract();
        this.getScanProductsExtractNew();
        const selectedLanguage = (LocalStorageServices.getItem(LANGUAGE) === 'en') ? true : false
        this.setState({ selectedLanguage })
        ReactDom.findDOMNode(this).scrollIntoView();
    }
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        className='custom-header cursor'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <Img
            style={{
                width: 10,
                marginLeft: 3
            }}
            decode={false}
            src={imageConstants.filterIcon}
        />
    </span>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'Montserrat': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    <div className='scrollable-menu'>
                        {React.Children.toArray(children[0])
                            .filter(
                                (child) =>
                                    !value || (child.props.children && child.props.children.toLowerCase().includes(value.toLowerCase())),
                            )
                        }
                    </div>
                    {React.Children.toArray(children[1])}
                </ul>
            </div>

        );
    },
);

const mapStateToProps = state => ({
    // scanMasterData: state.storeReducer.scanMaster,
    productMasterData: state.storeReducer.scanProducts
});

const mapDispatchToProps = dispatch => ({
    saveProductDataStatus: bindActionCreators(postProductsApprovalStatus, dispatch),
    filterData: bindActionCreators(filterScannedProduct, dispatch),
    getScanProductList: bindActionCreators(getScanProductList, dispatch),
    getScanProductDataExtract: bindActionCreators(getScanProductListExtract, dispatch),
    getScanProductDataExtractNew: bindActionCreators(getScanProductListExtractNew, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanProducts));
