import React from 'react';
import '../CustomModal.css';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { putCategoryData, getStoreListing, getCategoryListing } from '../../../../Services/Store&Product/action';
import { colorConstants } from '../../../../config/constants';
import Utils from '../../../../Utils/Utils';



const { translate } = Utils;
class CustomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            formControl: {
                name: React.createRef(),
                categoryNumber: React.createRef()
            },
            errorFlag: false,
            errorFlagCategory: false,
            errorRecreateId: true,
            errorMessage: false
        };
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        const { formControl } = this.state;
        const { putCategoryData, selectedItem, handleClose, getCategoriesData } = this.props;
        let payload = {
            "categoryName": formControl.name.current.value,
            "categoryNumber": formControl.categoryNumber.current.value.padStart(2, 0),
            "softDelete": 0
        }
        if (selectedItem) {
            payload['categoryId'] = parseInt(selectedItem.categoryId)
        }
        this.setState({ errorFlagCategory: false, errorFlag: false, errorRecreateId: false, errorMessage: false })
        putCategoryData(payload, (status, response) => {
            if (status) {
                getCategoriesData();
                handleClose();
            } else {
                if (response && response.status === 404) {
                    try {
                        response.json().then((res) => {
                            if (res.details && res.details[0]) {
                                if (res.details[0].includes('deleted')) {
                                    this.setState({ errorFlagCategory: true, errorRecreateId: true })
                                    return
                                }
                                if (res.details[0].includes('numeric')) {
                                    var message = translate.pleaseEnterNumberText;
                                    this.setState({ errorFlagCategory: true, errorRecreateId: true, errorMessage: message })
                                    return
                                }
                                if (res.details[0].includes('id')) {
                                    this.setState({ errorFlagCategory: true, errorRecreateId: false })
                                } else {
                                    this.setState({ errorFlag: true, errorRecreateId: false })
                                }
                            }
                        })
                    } catch (error) {
                        // console.log(error)
                    }
                }
            }
        })
    };


    render() {
        const { formControl, errorFlag, errorFlagCategory, errorRecreateId, errorMessage } = this.state
        const { showModal, handleClose, selectedItem } = this.props;
        return (
            <>
                <Modal dialogClassName="my-modal" show={showModal} onHide={handleClose} size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header class="modal-header" closeButton onHide={() => {
                        this.setState({ errorFlag: false, errorFlagCategory: false, errorMessage: false })
                    }}>
                        <Modal.Title className='header'>{selectedItem ? translate.editCategoryDetailsText : translate.addNewCategoryText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} controlId="categoryNumber">
                                <Form.Label column sm={2}>
                                    {translate.categoryIdText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.categoryNumber} defaultValue={selectedItem.categoryNumber || ''}
                                        type="text" placeholder="Category Id" />
                                    {
                                        errorFlagCategory ?
                                            <p style={{ fontSize: 14, color: colorConstants.redColor, paddingTop: 10 }}>{(errorMessage && errorMessage.length) ? errorMessage :  errorRecreateId ? translate.recreateText : translate.customErrorCategoryIdText}</p>
                                            : null
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="name">
                                <Form.Label column sm={2}>
                                    {translate.nameText}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control style={{ width: '90%', height: 50 }} required ref={formControl.name} defaultValue={selectedItem.categoryName || ''}
                                        type="text" placeholder="Name" />
                                    {
                                        errorFlag ?
                                            <p style={{ fontSize: 14, color: colorConstants.redColor, paddingTop: 10 }}>{translate.customErrorCategoryNameText}</p>
                                            : null
                                    }

                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} style={{ paddingTop: 10 }}>
                                <Form.Label column sm={2}>

                                </Form.Label>
                                <Col sm={2}>
                                    <Button type="submit" className="btn save-store" style={{
                                        width: 100,
                                        fontWeight: 'bold',
                                        backgroundColor: colorConstants.themeColor,
                                    }}>{translate.saveText}</Button>
                                </Col>
                                <Col sm={2} style={{ paddingLeft: 40 }}>
                                    <Button onClick={() => {
                                        this.setState({ errorFlag: false, errorFlagCategory: false, errorMessage: false })
                                        handleClose()
                                    }}
                                        style={{
                                            width: 100,
                                            fontWeight: 'bold',
                                            backgroundColor: colorConstants.whiteColor,
                                        }}><span style={{ color: colorConstants.themeColor }}>{translate.cancelText}</span></Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    //
});

const mapDispatchToProps = dispatch => ({
    putCategoryData: bindActionCreators(putCategoryData, dispatch),
    getListingData: bindActionCreators(getStoreListing, dispatch),
    getCategoriesData: bindActionCreators(getCategoryListing, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomModal));