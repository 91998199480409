import {
  API_URL,
  TEMP_API_URL,
  REDIRECT_URL,
  CLIENT_ID,
  CONGNITO_URL,
} from './envs/env.json';

const apiURL = API_URL;
const tempApiUrl = TEMP_API_URL;
const redirectURI = REDIRECT_URL;
const clientId = CLIENT_ID;
const cognitoURL = CONGNITO_URL;


// const apiURL =
//   'https://api-stage-temp.kpktest.axfood.se';
// const tempApiUrl =
//   'https://api-stage.kpktest.axfood.se';
// const redirectURI =
//   'https://stage.kpktest.axfood.se/&response_type=TOKEN&client_id=';
// const clientId = '14a607so3i6fbm86ii738cp463';
// const cognitoURL =
//   'https://axfoodtangolab.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=AzureTangoLab&redirect_uri=';



// const apiURL =
//   'https://api-temp.kpktest.axfood.se';
// const tempApiUrl =
//   'https://api.kpktest.axfood.se';
// const redirectURI =
//   'https://kpktest.axfood.se/&response_type=TOKEN&client_id=';
// const clientId = '14a607so3i6fbm86ii738cp463';
// const cognitoURL =
//   'https://axfoodtangolab.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=AzureTangoLab&redirect_uri=';



// const apiURL =
//   'https://api.kpk.axfood.se';
// const tempApiUrl =
//   'https://api.kpk.axfood.se';
// const redirectURI =
//   'https://kpk.axfood.se//&response_type=TOKEN&client_id=';
// const cognitoURL =
//   'https://axfood-production.auth.eu-west-1.amazoncognito.com/oauth2/authorize?identity_provider=cognito-prod-user&redirect_uri=';
// const clientId = '1sbg332ver9flq4u3dh4vcp3uf'


const baseURL = 'axfood';
const productScan = 'axfood-product-scan-mobile';
const tempProductScan = 'axfood-product-scan';
const security = 'axfood-security';

const apiConfig = {
  authenticationApi: {
    loginUserHandle: `/${baseURL}/${security}/login`,
  },
  storeApi: {
    storeListHandle: `/${baseURL}/${productScan}/stores`,
    categoryListHandle: `/${baseURL}/${productScan}/categories`,
    fetchProductData: `/${baseURL}/${productScan}/productDetails`,
    putProductDetails: `/${baseURL}/${productScan}/updateProductDetails/`,
    putImageData: `/${baseURL}/${productScan}/s3/productImage/upload`,
    getProductArray: `/${baseURL}/${productScan}/getProductDetailsListOfLastTransactions`,
    getConceptArray: `/${baseURL}/${productScan}/concepts`,
    getRetailerArray: `/${baseURL}/${productScan}/chains`,
    putStoreData: `/${baseURL}/${productScan}/addStore`,
    putCategoryData: `/${baseURL}/${productScan}/addCategory`,
    getScanMasterData: `/${baseURL}/${productScan}/scanMasterDetails`,
    getScanMaster:`/${baseURL}/${productScan}/scanmaster`,
    searchScanArticlesData: `/${baseURL}/${productScan}/scanMasterDetails/searchByGTINOrName`,
    deleteCategory: `/${baseURL}/${productScan}/deleteCategory`,
    fetchProductMaster: `/${baseURL}/${productScan}/allProducts`,
    searchProductMaster: `/${baseURL}/${productScan}/productByNameAndGtinAdmin`,
    getUsername: `/${baseURL}/${productScan}/username`,
    uploadProductMaster: `/${baseURL}/${productScan}/uploadExcelFile/product-master`,
    filterScannedArticles: `/${baseURL}/${productScan}/filterDuplicates/scanMasterDetails`,
    searchScanMaster : `/${baseURL}/${productScan}/scanMasterDetails/searchByStoreIdOrName`,
    downloadProductMaster: `/${baseURL}/${productScan}/downloadExcel`,
    getScanMasterDownloadData:`/${baseURL}/${productScan}/scanMasterDetails/homePage/filter/downloadExcel`,
    getScanMasterFilterList:`/${baseURL}/${productScan}/scanMasterDetails/homePage/filter`,
  },
  tempStoreApi: {
    storeListHandle: `/${baseURL}/${tempProductScan}/stores`,
    categoryListHandle: `/${baseURL}/${tempProductScan}/categories`,
    fetchProductData: `/${baseURL}/${tempProductScan}/productDetails`,
    putProductDetails: `/${baseURL}/${tempProductScan}/updateProductDetails/`,
    putImageData: `/${baseURL}/${tempProductScan}/s3/productImage/upload`,
    getProductArray: `/${baseURL}/${tempProductScan}/getProductDetailsListOfLastTransactions`,
    getConceptArray: `/${baseURL}/${tempProductScan}/concepts`,
    getRetailerArray: `/${baseURL}/${tempProductScan}/chains`,
    putStoreData: `/${baseURL}/${tempProductScan}/addStore`,
    putCategoryData: `/${baseURL}/${tempProductScan}/addCategory`,
    getScanMasterData: `/${baseURL}/${tempProductScan}/scanMasterDetails`,
    searchScanMasterData: `/${baseURL}/${productScan}/scanMasterDetails/searchByGTINOrName`,
    deleteCategory: `/${baseURL}/${tempProductScan}/deleteCategory`,
    getUsername: `/${baseURL}/${tempProductScan}/username`,
    fetchProductMaster: `/${baseURL}/${tempProductScan}/allProducts`,
    searchProductMaster: `/${baseURL}/${tempProductScan}/productByNameAndGtinAdmin`,
    uploadProductMaster: `/${baseURL}/${tempProductScan}/uploadExcelFile/product-master`,
    filterScannedArticles: `/${baseURL}/${tempProductScan}/filterDuplicates/scanMasterDetails`,
    getScanMasterDownloadData:`/${baseURL}/${tempProductScan}/scanMasterDetails/homePage/filter/downloadExcel`,
    getScanMasterFilterList:`/${baseURL}/${tempProductScan}/scanMasterDetails/homePage/filter`,
  },
  commonApi: {
    fetchQuantity: `/${baseURL}/${productScan}/quantity/unit`,
    fetchDiscountDetails: `/${baseURL}/${productScan}/discounts`,
  },
  tempCommonApi: {
    fetchQuantity: `/${baseURL}/${tempProductScan}/quantity/unit`,
    fetchDiscountDetails: `/${baseURL}/${tempProductScan}/discounts`,
  },
};

export default {
  apiURL,
  apiConfig,
  tempApiUrl,
  redirectURI,
  cognitoURL,
  clientId
};

