import LocalizedStrings from 'react-localization';
import React from 'react';
import { Spinner } from 'react-bootstrap'
import en from '../Translations/en';
import sw from '../Translations/sw'
import { colorConstants, LANGUAGE, EN } from '../config/constants';
import moment from "moment";
import LocalStorageServices from '../Services/LocalStorage';




// const translationGetters = {
//     // lazy requires (metro bundler does not support symlinks)
//     en: () => require('../Translations/en.json'),
//     sw: () => require('../Translations/sw.json'),
// };


// let strings = new LocalizedStrings({
//     en: () => require('../Translations/en.json'),
//     sw: () => require('../Translations/sw.json')
// });
let translate = new LocalizedStrings({
  sw: sw,
  en: en,
});


const ActivityIndicator = ({ style, size }) => {
  return (
    <Spinner
      as="span"
      animation="border"
      size={size}
      role="status"
      aria-hidden="true"
      style={{ color: colorConstants.textBlue, ...style }}
    />
  )
}

// const convertPrice = (price) => {
//   var updatedPrice = price && price.toFixed(2)
//   try {
//     var selectedLanguage = translate._language
//     if (typeof updatedPrice === 'string' && updatedPrice === 'null') {
//       updatedPrice = JSON.parse(updatedPrice);
//     }
//   } catch (error) {
//     //
//   }
//   if (updatedPrice) {
//     return selectedLanguage === 'en'
//       ? updatedPrice
//       : updatedPrice.replace('.', ',');
//   }
//   return '';
// };

const convertPrice = (price) => {
  var updatedPrice = price;
  console.log('updatedPrice',price);
  try {
    var selectedLanguage = translate._language; 
    if (typeof updatedPrice === 'string' && updatedPrice === 'null') {
      updatedPrice = JSON.parse(updatedPrice);
    }
    if (updatedPrice !== null) {
      const formattedPrice = selectedLanguage === 'en' ? updatedPrice.toFixed(2).toString() : updatedPrice.toFixed(2).toString().replace('.', ',');
      return formattedPrice;
    }
  } catch (error) {
    console.error('Error in convertPrice function:', error);
  }
  return '';
};

const convertStatus = (status) => {
  //
  if( status.toLowerCase() === 'approved')  {
    return translate.approvedText;
  }
  if( status.toLowerCase() === 'pending')  {
    return translate.pendingText;
  }
  if(status.toLowerCase() === 'rejected'){
    return translate.rejectedText;
  }
  if(status.toLowerCase() === 'all'){
    return translate.allText;
  }
  return status
  
}

const getDateString = (dateString) => {
  return moment(dateString).format('YYYY-MM-DD');
}


export default {
  translate,
  ActivityIndicator,
  getDateString,
  convertPrice,
  convertStatus,
};
