import React, { useState } from 'react';
import { Button } from 'react-bootstrap'
import './CategoryMaster.css';
import Img from 'react-image';
import CustomModal from '../../Common/Modal/CategoryModal/CategoryModal';
import DeleteModal from '../../Common/Modal/DeleteModal/DeleteModal';
import Utils from "../../../Utils/Utils";
import { imageConstants } from '../../../config/constants';
import { sortBy } from 'lodash';



let selectedValue = {};
const { translate } = Utils
const CategoryMaster = (props) => {
    const [show, setShow] = useState(false);
    const [deleteModalFlag, setDeleteModal] = useState(false)

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleDeleteShow = () => setDeleteModal(true);
    const handleDeleteClose = () => setDeleteModal(false);
    return (
        <div className='bottom-div'>
            <div className="d-flex flex-row justify-content-between">
                <p className='custom-store'>{translate.categoryMasterText}</p>
                <div>
                    <Button className="btn add-store" onClick={() => {
                        handleShow()
                        selectedValue = false;
                    }}>
                        <Img
                            style={{
                                width: 25,
                                height: 24,
                                marginRight: 10
                            }}

                            src={imageConstants.buttonIcon}
                        />
                        <span className='custom-class'>{translate.addNewCategoryText}</span>
                    </Button>
                </div>

            </div>
            {<CustomModal showModal={show} handleClose={handleClose} selectedItem={selectedValue} />}
            <DeleteModal showModal={deleteModalFlag}
                handleClose={() => {
                    handleDeleteClose()
                }}
                confirmDelete={() => {
                    props.deleteCategory(selectedValue.categoryId, (status, response) => {
                        if (status) {
                            props.getCategoriesData();
                            handleDeleteClose()
                        }
                    })
                    selectedValue = {}
                }}
            />
            <table className="table table-borderless custom-table">
                <thead>
                    <tr>
                        <th scope="col">{translate.categoryIdText}</th>
                        <th scope="col" className="w-100">{translate.nameText}</th>
                        <th scope="col">{translate.actionText}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.categoriesData && sortBy(props.categoriesData.map((item) =>{
                            item.sortByNumber = Number(item.categoryNumber)
                            return item
                        }), 'sortByNumber').map((item, index) => {
                            return (
                                <tr key={index} className='shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-2'>
                                    <td >{item.categoryNumber}</td>
                                    <td style={{ textTransform: 'capitalize' }}>{item.categoryName}</td>
                                    <td className="action-css">
                                        <div className="Rounded-Rectangle-14 rounded cursor" onClick={() => {
                                            handleShow()
                                            selectedValue = item;
                                        }}>
                                            <Img decode={false} src={imageConstants.editIcon}
                                                className="Forma-1" />
                                        </div>
                                        <div className="Rounded-Rectangle-14-delete rounded cursor" onClick={() => {
                                            handleDeleteShow()
                                            selectedValue = item;
                                           
                                        }}>
                                            <Img decode={false} src={imageConstants.deleteIcon}
                                                className="Forma-1-delete " />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
};

export default CategoryMaster;