import React, { Component, createRef } from 'react';
import { Button, Form } from 'react-bootstrap'
import './ProductMaster.css';
import Img from 'react-image';
import Utils from "../../../Utils/Utils";
import { imageConstants, colorConstants } from '../../../config/constants';
import Download from '../../Common/DownloadExcel';
import { bindActionCreators } from 'redux';
import { getProductMasterListing, searchProductMasterData, downloadExcel } from '../../../Services/Store&Product/action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableSearchBar from '../../Common/SearchBar/SearchBar';
import LocalStorageServices from '../../../Services/LocalStorage';


const { translate, ActivityIndicator } = Utils

class ProductMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null,
            searchText: null,
            searchData: null,
            searchLoader: false,
            productMasterFlag: false,
        }
        this.inputFile = createRef(null)
    }

    onButtonClick = () => {
        const { resetState } = this.props
        resetState();
        this.inputFile.current.click();
        this.searchData(null);
        this.inputFile = createRef(null)
    };

    getUpdatedProductMaster() {
        const { productMasterData } = this.props;
        const { selection } = this.state;

        if (selection === '1') {
            return productMasterData || [];
        }
        if (selection === '2') {
            let updatedData = productMasterData.filter((item) => {
                return item.barcoded;
            })
            return updatedData || []
        }
        if (selection === '3') {
            let updatedData = productMasterData.filter((item) => {
                return !item.barcoded;
            })
            return updatedData || []
        }
    }

    handleLogout() {
        LocalStorageServices.removeAllItem();
        this.props.history.replace('/');
    }


    searchData(value,event) {
        this.setState({ searchText: value },() => {
            this.keyPressed(event)
        })
        if ((value && value.length === 0 )|| !value) {
            this.setState({ searchData: null, searchLoader: false })
        }
    }

    keyPressed(event) {
        const { searchText } = this.state
        const { searchProductMaster, resetState } = this.props
        if (event && searchText && searchText.length > 1) {
            this.setState({ searchLoader: true })
            resetState();
            searchProductMaster(searchText, (status, response) => {
                if (status) {
                    this.setState({ searchData: response, searchLoader: false })
                } else {
                    this.setState({ searchData: null, searchLoader: false })
                }
            })
        } else {
            this.setState({ searchData: null, searchLoader: false })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.productMasterFlag !== state.productMasterFlag) {
            return {
                productMasterFlag: props.productMasterFlag || state.productMasterFlag
            }
        }
        return null;
    }

    render() {
        const { selection, searchData, searchLoader, productMasterFlag } = this.state;
        const { failedRecords, uploadStatus, downloadProductMaster } = this.props;
        return (
            <>
                <TableSearchBar keyPressed={this.keyPressed.bind(this)} onLogoutClick={this.handleLogout.bind(this)} onSearch={(value,event) => this.searchData(value,event)} placeholderText={translate.searchByProductMasterText} />
                <div className='bottom-div custom-display'>
                    <div className="d-flex flex-row justify-content-between">
                        <p className='custom-store'>{translate.productMasterText}</p>
                        <div>
                            <Download
                                downloadProductMaster={(fileName,selection) => {
                                    this.searchData(null)
                                    this.setState({searchLoader: true})
                                    downloadProductMaster(fileName,selection,(status) => {
                                        this.setState({searchLoader: false})
                                    })
                                }}
                                element={!productMasterFlag ?
                                    <>
                                        <Button className="btn-sm" style={{
                                            marginRight: 20,
                                            backgroundColor: colorConstants.borderGreyColor,
                                            borderColor: colorConstants.clearGreyColor,
                                            pointerEvents: selection ? 'all' : 'none',
                                            padding: '5px 20px 5px'
                                        }}>
                                            <ActivityIndicator
                                                size='sm'
                                                style={{
                                                    alignSelf: "center",
                                                }} /></Button>
                                    </>
                                    :
                                    null
                                }
                                selection={selection} data={{ type: 'productMaster', value: this.getUpdatedProductMaster() }} />
                            <Button className={`btn-sm ${uploadStatus ? 'disabled' : null}`}
                                style={{
                                    marginRight: 20,
                                    backgroundColor: colorConstants.themeColor,
                                    borderColor: colorConstants.themeColor
                                }}
                                onClick={uploadStatus ? null : this.onButtonClick}>
                                <Img
                                    style={{
                                        width: 25,
                                        height: 24,
                                        marginRight: 10
                                    }}

                                    src={imageConstants.fileUploadIcon}
                                />

                                <span className='custom-class' style={{ color: colorConstants.whiteColor }}>{translate.importProductsText}</span>
                                <input onChange={(event) => {
                                    this.props.onFileUpload(event);
                                    this.setState({ uploadStatus: true });
                                }}
                                    type='file'
                                    id='file'
                                    accept=".xls,.xlsx"
                                    ref={this.inputFile}
                                    style={{ display: 'none' }} />
                            </Button>

                        </div>

                    </div>
                    <Form>
                        {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3" onChange={(event) => {
                                this.setState({ selection: event.target.id })
                            }}>
                                <Form.Check name="selection" inline label={translate.allProductsText} type={type} id={'1'} />
                                <Form.Check name="selection" inline label={translate.searchableProductsText} type={type} id={'2'} />
                                <Form.Check name="selection" inline label={translate.nonSearchableProductsText} type={type} id={'3'} />
                            </div>
                        ))}
                    </Form>
                    {
                        (this.props.showFileUploadStatus) ?
                            this.props.statusText ?
                                <>
                                    <div style={{
                                        marginTop: 5,
                                        color: colorConstants.themeColor,
                                        fontWeight: 'bold',
                                        backgroundColor: colorConstants.lightBlue,
                                        padding: 15,
                                        borderRadius: 5,
                                    }}>{`${this.props.statusText} ${translate.importSuccessText}`}</div>
                                    {
                                        (failedRecords && failedRecords.length) ?
                                            <Download element={
                                                <div style={{
                                                    marginTop: 10,
                                                    marginLeft: 15,
                                                    color: colorConstants.redColor,
                                                    width: '15%',
                                                    fontWeight: 'bold',
                                                    cursor: 'pointer'
                                                }}>{translate.dataUploadError}</div>
                                            } data={{ type: 'productMaster', value: failedRecords }}></Download>
                                            :
                                            null
                                    }
                                </>
                                :
                                <div style={{
                                    marginTop: 10,
                                    marginLeft: 15,
                                    color: colorConstants.redColor,
                                    width: '13%',
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                }}>{translate.failedUploadText}</div>
                            :
                            null
                    }
                    {
                        searchData && searchData.length ?
                            <table className="table table-borderless custom-table ">
                                <thead>
                                    <tr>
                                        <th scope="col" className="w-10">{translate.barcodeIdText}</th>
                                        <th scope="col" className="w-30">{translate.productNameText}</th>
                                        <th scope="col" className="w-100">{translate.searchStatusText}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchData ?
                                            searchData.map((item, index) => {
                                                return (
                                                    <tr key={index} className='shadow-sm rounded bg-white custom-border ml-1 mr-2 p-1 mb-4'>
                                                        <td >{item.barcodeId}</td>
                                                        <td style={{
                                                            textTransform: 'capitalize',
                                                            paddingTop: 20,
                                                            paddingBottom: 20
                                                        }}>{item.productName}</td>
                                                        {
                                                            item.barcoded ?
                                                                <td style={{ textTransform: 'capitalize' }}><span style={{
                                                                    backgroundColor: colorConstants.lightBlue,
                                                                    padding: 10,
                                                                    borderRadius: 3,
                                                                    borderLeftStyle: 'solid',
                                                                    borderLeftWidth: 3,
                                                                    borderLeftColor: colorConstants.orangeColor
                                                                }}
                                                                >
                                                                    {translate.searchableText}
                                                                </span>
                                                                </td>
                                                                :
                                                                <td style={{ textTransform: 'capitalize' }}>
                                                                    <span
                                                                        style={{
                                                                            backgroundColor: colorConstants.lightBlue,
                                                                            padding: 10,
                                                                            borderRadius: 3,
                                                                            borderLeftStyle: 'solid',
                                                                            borderLeftWidth: 3,
                                                                            borderLeftColor: colorConstants.blueHighlightColor
                                                                        }}
                                                                    >
                                                                        {translate.nonSearchableText}
                                                                    </span>
                                                                </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </tbody>
                            </table>
                            :
                            searchData ?
                                <div style={{
                                    marginTop: 5,
                                    color: colorConstants.themeColor,
                                    fontWeight: 'bold',
                                    backgroundColor: colorConstants.lightBlue,
                                    padding: 15,
                                    borderRadius: 5,
                                }}>{translate.noRecordsFoundText}</div>
                                :
                                null

                    }
                    {
                        (uploadStatus || searchLoader) ?
                            <>
                                <ActivityIndicator style={{
                                    marginRight: 20,
                                    alignSelf: "center",
                                    marginTop: 150,
                                    height: 100,
                                    width: 100
                                }} />
                                {
                                    uploadStatus ?
                                        <span style={{
                                            marginRight: 20,
                                            marginTop: 50,
                                            alignSelf: "center",
                                        }}>{translate.importingProductsText}</span>
                                        :
                                        null
                                }
                            </>
                            :
                            null
                    }
                </div>
            </>
        );
    }

    componentDidMount() {
        this.props.getProductMaster((status) => {
            this.setState({ productMasterFlag: true })
        });
    }
}


const mapStateToProps = state => ({
    productMasterData: state.storeReducer.productMasterData,
});

const mapDispatchToProps = dispatch => ({
    getProductMaster: bindActionCreators(getProductMasterListing, dispatch),
    searchProductMaster: bindActionCreators(searchProductMasterData, dispatch),
    downloadProductMaster: bindActionCreators(downloadExcel, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductMaster));

