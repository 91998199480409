import React from "react";
import ReactExport from "react-export-excel";
import { Button } from 'react-bootstrap'
import Img from 'react-image';
import Utils from "../../Utils/Utils";
import { colorConstants, imageConstants } from "../../config/constants";


const { translate, convertStatus } = Utils;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Download extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onPress: false
        }
        this.childButtonRef = React.createRef(null);
    }

    typeOfDownload(data) {
        switch (data.type) {
            case 'scanData':
                return (
                    <ExcelSheet data={data.value} name={translate.scanMasterText}>
                        <ExcelColumn label={translate.gtinCapsTEXT}
                            value={(col) => col && col.barcodeId} />
                        <ExcelColumn label={translate.productNameText}
                            value={(col) => col && col.productName} />
                        <ExcelColumn label={translate.DescriptionText}
                            value={(col) => col && col.text} />
                        <ExcelColumn label={translate.regularPriceText} value="price" />
                        <ExcelColumn label={translate.uomText}
                            value={(col) => col && col.measurementUnit && col.measurementUnit.unitName.toUpperCase()} />
                        <ExcelColumn label={translate.discountedQuantityText} value={(col) => col.discountedQuantity || ''} />
                        <ExcelColumn label={translate.discountedUomText} value={(col) => (col.discountedUnit && col.discountedUnit.toUpperCase()) || ''} />
                        <ExcelColumn label={translate.discountedPriceText} value={(col) => col.discountedPrice || ''} />
                        <ExcelColumn label={translate.discountedTypeText} value={(col) => (col.discount && col.discount.name) || ''} />
                        <ExcelColumn label={translate.comparableGtinCapsTEXT} value={(col) => col && col.comparableGtin || ''} />
                        <ExcelColumn label={translate.comparableDescriptionText} value={(col) => col && col.comparableDescription || ''} />
                        <ExcelColumn label={translate.dateText} value="transactionDate" />
                        <ExcelColumn label={translate.scannedByText} value={(item) => (item.userName && item.userName.substring(item.userName.lastIndexOf("_") + 1, item.userName.lastIndexOf("@"))) || item.userName} />
                        <ExcelColumn label={translate.imageUrlText} value={(images) => Array.isArray(images.imageUrl) ? images.imageUrl.join(", ") : images.imageUrl} />
                        <ExcelColumn label={translate.statusText} value={(col) => (col.status && convertStatus(col.status)) || ''} />
                    </ExcelSheet>
                )
            case 'storeMasterData':
                return (
                    <ExcelSheet data={data.value.filter((item) => { return item.zipCode })} name={translate.storeMasterText}>
                        <ExcelColumn label={translate.retailerNumber}
                            value={(col) => col && col.retailer && col.concept?.retailer?.retailerNumber} />
                        <ExcelColumn label={translate.retailerText}
                            value={(col) => col && col.retailer && col.retailer.retailerName} />
                        <ExcelColumn label={translate.conceptIdText}
                            value={(col) => col && col.concept && col.concept.conceptNumber} />
                        <ExcelColumn label={translate.conceptText}
                            value={(col) => col && col.concept && col.concept.name} />
                        <ExcelColumn label={translate.storeNumberText} value="storeNumber" />
                        <ExcelColumn label={translate.nameText} value="storeName" />
                        <ExcelColumn label={translate.addressText} value="storeAddress" />
                        <ExcelColumn label={translate.cityText} value="city" />
                        <ExcelColumn label={translate.latitudeText} value="latitude" />
                        <ExcelColumn label={translate.longitudeText} value="longitude" />
                        <ExcelColumn label={translate.samePriceStoreText} value={(col) => col && col.samePriceOnlineOffline ? translate.yesText : translate.noText} />
                        <ExcelColumn label={translate.sendConsumerPriceText} value={(col) => col && col.exportToConsumerPricingSystem ? translate.yesText : translate.noText} />
                        <ExcelColumn label={translate.zipCodeText} value="zipCode" />
                    </ExcelSheet>
                )
            case 'productMaster':
                return (
                    <ExcelSheet data={data.value} name={translate.productMasterText}>
                        <ExcelColumn label={translate.articleText} value={(col) => (col && col.productNumberSAP && col.productNumberSAP) || ''} />
                        <ExcelColumn label={translate.gtinCapsTEXT} value={(col) => (col && col.barcodeId && JSON.stringify(col.barcodeId)) || ''} />
                        <ExcelColumn label={translate.comparableGtinCapsTEXT} value={(col) => col && col.comparableGtin || ''} />
                        <ExcelColumn label={translate.comparableDescriptionText} value={(col) => col && col.comparableDescription || ''} />
                        <ExcelColumn label={translate.articleNameText} value={(col) => (col && col.productName) || ''} />
                        <ExcelColumn label={translate.productGroupText} value={(col) => col && col.primaryCategory && col.primaryCategory.categoryNumber} />
                        <ExcelColumn label={translate.wightVolumeText} value={(col) => (col && col.volumeLabel) || ''} />
                        <ExcelColumn label={translate.salesUnitText}
                            value={(col) => col && col.measurementUnit && col.measurementUnit.unitName.toUpperCase()} />
                        <ExcelColumn label={translate.searchableText} value={(col) => (col && col.barcoded) ? 'x' : ''} />
                    </ExcelSheet>
                )
            case 'scanMasterData':
                return (
                    <ExcelSheet data={data.value} name={translate.scanMasterText}>
                        <ExcelColumn label={translate.storeIdText} value={(col) => (col && col.storeNumber && col.storeNumber) || ''} />
                        <ExcelColumn label={translate.nameText} value={(col) => (col && col.name) || ''} />
                        <ExcelColumn label={translate.addressText} value={(col) => (col && col.address) || ''} />
                        <ExcelColumn label={translate.conceptText} value={(col) => col && col.concept} />
                        <ExcelColumn label={translate.source} value={(col) => (col && col.source) || ''} />
                        <ExcelColumn label={translate.scanDateText} value={(col) => (col && col.lastTransactionDate) || ''} />
                    </ExcelSheet>
                )
            default:
            // code block
        }
    }


    generateFileName(data) {
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var hour = today.getHours();
        var millisecond = today.getMinutes();
        var seconds = today.getSeconds();
        var fileName = '';
        switch (data.type) {
            case 'scanData':
                fileName = translate.scanMasterText;
                break
            case 'storeMasterData':
                fileName = translate.storeMasterText;
                break
            case 'productMaster':
                fileName = translate.productMasterText;
                break
            case 'scanMasterData':
                fileName = translate.scanMasterText;
                break
            default:
            // code block
        }
        return fileName + "_" + year + "-" + month + "-" + day + "_" + hour + "-" + millisecond + "-" + seconds;
    }

    render() {
        const { data, selection, element } = this.props;
        return (
            <>
                {
                    ((data && data.type === 'productMaster') && !element) ?
                        <Button
                            onClick={() => {
                                if (this.props.selection) {
                                    let fileName = this.generateFileName(data)
                                    this.props.downloadProductMaster(fileName, selection);
                                }
                            }}
                            className="btn-sm" style={{
                                marginRight: 20,
                                marginLeft: 20,
                                backgroundColor: selection ? colorConstants.lightBlue : colorConstants.borderGreyColor,
                                borderColor: selection ? colorConstants.darkBlue : colorConstants.clearGreyColor,
                                pointerEvents: selection ? 'all' : 'none'
                            }}>
                            <Img
                                style={{
                                    width: 27,
                                    height: 26,
                                    marginRight: 10
                                }}
                                decode={false}
                                src={imageConstants.extractIcon}
                            />
                            <span className='custom-class' style={{ color: colorConstants.textBlue }}>{translate.extractText}</span></Button>
                        :
                        <>
                            {data.type === 'scanData' && <Button
                                className="btn-sm"
                                style={{
                                    marginRight: 20,
                                    marginLeft: 20,
                                    backgroundColor: selection ? colorConstants.lightBlue : colorConstants.borderGreyColor,
                                    borderColor: selection ? colorConstants.darkBlue : colorConstants.clearGreyColor,
                                    pointerEvents: selection ? 'all' : 'none'
                                }}
                                onClick={() => {
                                    this.setState({ onPress: true }, () => {
                                        setTimeout(() => {
                                            this.setState({ onPress: false });
                                            // alert('Hello');
                                        }, 2000);
                                        setTimeout(() => {
                                            this.childButtonRef.current.click();
                                        }, 3000)
                                    })

                                    console.log('done!');
                                }}>
                                <Img
                                    style={{
                                        width: 27,
                                        height: 26,
                                        marginRight: 10
                                    }}
                                    decode={false}
                                    src={imageConstants.extractIcon}
                                />
                                <span className='custom-class' style={{ color: colorConstants.textBlue }}>{translate.extractText}</span>
                            </Button>}
                            <ExcelFile filename={this.generateFileName(data)} element={
                                element ?
                                    element :
                                    data.type === 'scanData' ?
                                        <div ref={this.childButtonRef} >
                                            {this.state.onPress && <div>
                                                <span className='custom-class' style={{ color: colorConstants.textBlue }}>{"Download in Progress ...."}</span>
                                            </div>}
                                        </div> :
                                        <Button className="btn-sm" style={{
                                            marginRight: 20,
                                            marginLeft: 20,
                                            backgroundColor: selection ? colorConstants.lightBlue : colorConstants.borderGreyColor,
                                            borderColor: selection ? colorConstants.darkBlue : colorConstants.clearGreyColor,
                                            pointerEvents: selection ? 'all' : 'none'
                                        }}>
                                            <Img
                                                style={{
                                                    width: 27,
                                                    height: 26,
                                                    marginRight: 10
                                                }}
                                                decode={false}
                                                src={imageConstants.extractIcon}
                                            />
                                            <span className='custom-class' style={{ color: colorConstants.textBlue }}>{translate.extractText}</span></Button>

                            }
                            >
                                {this.typeOfDownload(data)}
                            </ExcelFile>
                        </>

                }
            </>
        );
    }
}
