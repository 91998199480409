import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Utils from '../../Utils/Utils';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from '../Login/Login'
import Home from '../Home/Home'
import LocalStorageServices from '../../Services/LocalStorage';
import { TOKEN, LANGUAGE } from '../../config/constants';



const { translate } = Utils;

if(!LocalStorageServices.getItem(LANGUAGE)){
  LocalStorageServices.setItem(LANGUAGE, 'sw')
  translate.setLanguage('sw');
}


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  // production code
  console.log = console.warn = console.error = () => { };

}

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function checkAuth() {
  return LocalStorageServices.getItem(TOKEN) ? true : false;
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkAuth() ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export default App;
